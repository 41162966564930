import type { NavigationExtras, Router } from '@angular/router';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type {
  ProjectViewProject,
  SearchActiveProject,
  Skill,
  User,
} from '@freelancer/datastore/collections';
import {
  CountryCode,
  QuotationItemType,
  acceptedBid,
  attachedToQuotation,
  awardProjectRichMessage,
  awardedProject,
  clearedMilestone,
  currencyUSD,
  fixedUsdProject,
  freelancerUser,
  frozenMilestone,
  graphicDesignSkill,
  hourlyUsdProject,
  logoDesignSkill,
  milestoneFromBid,
  milestoneFromQuotationItemRevision,
  offsitingMessageReminderFreelancerRichMessage,
  projectViewProjectFromProject,
  pythonSkill,
  skillsLimitBenefit,
  unverifiedUser,
  updateProjectViewProjectBidStats,
  usdCurrency,
  userSkillsFromSkills,
  websiteDesignSkill,
} from '@freelancer/datastore/collections';
import type { DatastoreInterface } from '@freelancer/datastore/core';
import { LOGGED_OUT_KEY } from '@freelancer/datastore/core';
import type { DatastoreTestingController } from '@freelancer/datastore/testing';
import {
  debugConsoleGroupCollapsed,
  debugConsoleGroupEnd,
} from '@freelancer/datastore/testing';
import {
  Paragraph,
  generateId,
  getParagraphs,
  peopleWithSurnames,
} from '@freelancer/datastore/testing/helpers';
import { LoginTestingUsernames } from '@freelancer/login-signup/testing';
import { toNumber } from '@freelancer/utils';
import { ContextTypeApi } from 'api-typings/messages/messages_types';
import { ProjectTypeApi } from 'api-typings/projects/projects';
import {
  QuotationContextTypeApi,
  QuotationItemBillingFrequencyApi,
  QuotationStatusApi,
  ScheduledSubscriptionPaymentStatusApi,
  SubscriptionItemStatusApi,
} from 'api-typings/quotations/quotations';
import type { Subscription } from 'rxjs';
import {
  createBanners,
  createBids,
  createContestBudgetRanges,
  createContestFees,
  createCountries,
  createCurrencies,
  createDomain,
  createFreelancerReputations,
  createLanguage,
  createMembershipBenefit,
  createMessage,
  createMessages,
  createMilestone,
  createNotificationsPreferences,
  createOnlineOffline,
  createProfileViewUser,
  createProjectBudgetOptions,
  createProjectUpgradeFees,
  createProjectViewBids,
  createProjectViewProject,
  createProjectViewUser,
  createProjectViewUsers,
  createQuotationItemRevision,
  createQuotationRevision,
  createReferralInvitationCheck,
  createScheduledSubscriptionPayment,
  createSearchActiveProject,
  createSiteStat,
  createSkills,
  createSubscriptionItem,
  createThread,
  createUser,
  createUserBalances,
  createUserCalifornianStatuses,
  createUserGiveGetDetail,
  createUserInfo,
  createUserInteraction,
  createUserRecentProjectsAndContests,
  createUserRequiresPhoneVerification,
  createUserSkills,
  createUsers,
  createUsersProfile,
  createUsersSelf,
  signupAndLogin,
} from '../document-creators';
import {
  constructAppStoreProfileState,
  constructAppStoreProjectViewPagePaymentTabState,
  constructFreelancerProfilePageState,
  createAppStoreProfileUser,
} from './app-store';

/**
 * This is to make it easy to make a version of `go` that just works
 * for this file.
 *
 * This version of `go` is designed to emulate the one for UI tests
 * and be used for fake-driven development. It's deliberately not
 * exported so that it's not accidentally imported in UI tests
 *
 * The reason this is needed as we can't call `TestBed.inject(Router)`
 * outside of a test.
 */
let globalRouter: Router | undefined;

async function go(path: string, extras?: NavigationExtras): Promise<boolean> {
  if (!globalRouter) {
    throw new Error(
      'Missing global router. This should have been set in `constructInitialStoreState`',
    );
  }

  return globalRouter.navigate([path], extras);
}

/**
 * Create initial state
 *
 * Multiple personas are included here which you can enable by logging in
 * as one of them. Please improve these over time.
 *
 * The `appStoreScreenshotsUser` is used for the app store screenshots.
 * Please only update it if you mean to.
 *
 * Alternatively you can start the webapp with `BLANK_STATE=true`
 * which will not do any default setup and instead will start the app
 * with a blank datastore state and call `constructStateForUiTest`.
 * This is useful for setting up the state in a way which you can
 * then copy for a UI test.
 */
export async function constructInitialStoreState(
  auth: AuthServiceInterface,
  datastore: DatastoreInterface,
  datastoreController: DatastoreTestingController,
  router: Router,
  blankState: boolean,
): Promise<Subscription | void> {
  globalRouter = router; // Used for local copy of `go`

  /**
   * Set via BLANK_STATE=true
   */
  if (blankState) {
    await constructStateForUiTest();
    return;
  }

  /**
   * If you are running these in a "dev" mode then we want to set
   * up the state for a given persona (based on the user you log in as)
   */
  return auth.authState$.subscribe(async authState => {
    const authUid = toNumber(authState ? authState.userId : LOGGED_OUT_KEY);

    /** Print all the initial setup logs in a grouped section. */
    debugConsoleGroupCollapsed('Initial datastore state creation');

    if (await datastoreController.isStateEmpty()) {
      await constructGlobalState(authUid);

      switch (authUid) {
        case toNumber(LOGGED_OUT_KEY):
          break;
        case LoginTestingUsernames.basicEmployer:
          await constructCommonUserState({ authUid });
          await constructInitialEmployerStoreState(datastore, authUid);
          break;
        case LoginTestingUsernames.basicFreelancer:
          await constructNewFreelancerState(authUid);
          break;
        case LoginTestingUsernames.experiencedEmployer:
        case LoginTestingUsernames.experiencedFreelancer:
          console.warn(
            'These are presently blank, please help implementing them.',
          );
          break;
        case LoginTestingUsernames.appStoreScreenshotsUser: {
          const employer = await createAppStoreProfileUser(authUid);
          await constructInitialStoreStateForAppStoreScreenshots(employer);
          break;
        }
        default:
          /**
           * This is often an error unless you're deliberately testing account creation.
           *
           * The most likely reason for this to occur accidentally is probably because
           * the fake auth service caches the user you are logged in as. Clearing session
           * storage or opening a new tab will most likely "fix" this.
           */
          console.error(
            'Trying to construct the state for a new or unknown user. This may be accidental',
          );
          break;
      }
    }

    debugConsoleGroupEnd();
  });
}

/**
 * This function is designed so that you can copy your initial UI test setup for debugging.
 *
 * It can (should?) call a custom version of `go` to navigate to the page that you want.
 */
async function constructStateForUiTest(): Promise<void> {
  // Put whatever setup code you want here

  await signupAndLogin();
  const project = await createProjectViewProject({ ownerId: generateId() });

  await go(`/projects/${project.id}`);

  console.error({ project });
}

// States not specific to a particular user,
// eg. supported currencies, skills.
export async function constructGlobalState(authUid: number): Promise<void> {
  await createSiteStat({ userId: authUid });
  await createLanguage();
  await createDomain();
  await createCurrencies();
  await createSkills();
  await createCountries({ countryCodes: [CountryCode.US] });
  await createContestFees();
}

// User related state that often required by most of the pages.
export async function constructCommonUserState({
  authUid,
  searchLanguages = ['en'],
  skills = [websiteDesignSkill(), graphicDesignSkill(), logoDesignSkill()],
}: {
  readonly authUid: number;
  readonly searchLanguages?: readonly string[];
  readonly skills?: readonly Skill[];
}): Promise<void> {
  await createUserSkills({
    userId: authUid,
    ...userSkillsFromSkills(skills),
  });
  await createUsersProfile({ userId: authUid, searchLanguages });

  /** Ref T254483: Clean up after A/B test */
  await createUserRequiresPhoneVerification({
    userId: authUid.toString(),
    userRequiresPhoneVerification: false,
  });
  const searchProjects = await constructSearchProjectsState(authUid);
  await Promise.all(
    searchProjects.map(async searchProject => {
      await createProjectViewProject({
        ...projectViewProjectFromProject(searchProject),
      });
    }),
  );
}

export async function constructInitialEmployerStoreState(
  datastore: DatastoreInterface,
  authUid: number,
): Promise<void> {
  // Create self and other me things
  const { id: userId, username } = await createUsersSelf({
    userId: authUid,
  });
  const user = await createUser({ userId });

  // Used on the webapp playground
  await createUser({ userId: 13_223 });
  await createUser({ userId: 133_223 });

  await createUserInfo({ userId });
  await createUserGiveGetDetail({ userId, username });
  await createUserCalifornianStatuses({ userIds: [userId] });
  await createUserBalances();
  await createBanners();
  await createReferralInvitationCheck({ userId });
  await createNotificationsPreferences({});

  // Create project for PVP
  const normalProject = await createProjectViewProject({
    projectId: 42,
    ownerId: userId,
    title: 'Build me a website',
  });
  const { id: projectId } = normalProject;
  await createProjectUpgradeFees({ projectId });
  const bidders = await createUsers({ names: peopleWithSurnames });
  await createProjectViewUsers({
    users: [user, ...bidders],
  });

  const bidderIds = bidders.map(bidder => bidder.id);
  const bids = await createBids({
    bidderIds,
    projectId,
    projectOwnerId: userId,
    minAmount: 100,
    maxAmount: 200,
  });
  await createProjectViewBids({ bids });
  await updateProjectViewProjectBidStats(datastore, { projectId, bids });
  await createFreelancerReputations({ freelancerIds: bidderIds });
  await createOnlineOffline({ userIds: bidderIds });

  const quoteProject = await createQuotationProject({
    freelancer: bidders[0],
    employer: user,
  });

  // Put projects in nav
  await createUserRecentProjectsAndContests({
    projects: [normalProject, quoteProject],
  });

  // Create for PJP
  await createProjectBudgetOptions({
    projectType: ProjectTypeApi.FIXED,
  });
  await createProjectBudgetOptions({
    projectType: ProjectTypeApi.HOURLY,
  });
  await createContestBudgetRanges();

  // Create some messages
  const freelancerBotUser = await createUser({
    username: 'plagiarismDetector3000',
    displayName: 'Plagiarism Detector 3000',
  });

  const awardedThread = await createThread({
    userId: authUid,
    otherMembers: bidderIds.slice(0, 1),
    context: { type: ContextTypeApi.PROJECT, id: normalProject.id },
  });
  await createMessage({
    fromUserId: authUid,
    threadId: awardedThread.id,
    message: 'Hi there',
  });

  await createMessage({
    ...awardProjectRichMessage({
      freelancerBotUser,
      thread: awardedThread,
      bidId: bids[0].id,
    }),
  });

  const offsitingThread = await createThread({
    userId: authUid,
    otherMembers: bidderIds.slice(1, 2),
    context: { type: ContextTypeApi.PROJECT, id: normalProject.id },
  });
  await createMessage({
    fromUserId: authUid,
    threadId: offsitingThread.id,
    message: 'Pay me over Skype',
  });

  await createMessage({
    ...offsitingMessageReminderFreelancerRichMessage({
      freelancerBotUser,
      thread: offsitingThread,
    }),
  });

  /** A few threads reading books to you. */
  Promise.all(
    Object.keys(Paragraph)
      .map(paragraphKey =>
        getParagraphs(Paragraph[paragraphKey as keyof typeof Paragraph]),
      )
      .map(async (paragraph, index) =>
        createMessages({
          fromUserId: authUid,
          threadId: generateId(),
          messages: paragraph,
        }),
      ),
  );
}

export async function constructNewFreelancerState(
  userId: number,
): Promise<void> {
  // Create self and other me things
  await createUsersSelf({
    userId,
    ...unverifiedUser(),
  });
  const user = await createUser({ userId, ...unverifiedUser() });
  await createUsersProfile({ userId });
  await createProfileViewUser({ user });
  await createUserSkills({ userId, skills: [] });
  await createUserRequiresPhoneVerification({
    userId: `${userId}`,
    userRequiresPhoneVerification: false,
  });

  await createMembershipBenefit(skillsLimitBenefit());
}

export async function constructSearchProjectsState(
  authUid: number,
): Promise<readonly SearchActiveProject[]> {
  await createLanguage();
  return Promise.all([
    createSearchActiveProject({
      ...fixedUsdProject(),
      budget: {
        minimum: 150,
        maximum: 250,
      },
      bidStats: { bidCount: 2, bidAvg: 170 },
      skills: [websiteDesignSkill(), pythonSkill()],
      ownerId: generateId(),
      description: `I have a shop website for my special customer. I need to update website mobile friendly and input some functionalities. You must be versed in front-end and back-end coding.`,
      title: 'Website for my coffee shop',
    }),
    createSearchActiveProject({
      ...fixedUsdProject(),
      budget: {
        minimum: 100,
      },
      bidStats: { bidCount: 2, bidAvg: 125 },
      skills: [logoDesignSkill()],
      ownerId: generateId(),
      description:
        'I am registering a company for my real estate business and I need a creative logo design.',
      title: 'New company logo design',
    }),
    // We would like this project to show first in the search result.
    // Hence, we are keeping this budget to be a minimum.
    createSearchActiveProject({
      ...hourlyUsdProject(),
      budget: {
        minimum: 23,
        maximum: 33,
      },
      bidStats: { bidCount: 2, bidAvg: 28 },
      skills: [graphicDesignSkill()],
      ownerId: generateId(),
      description:
        'I need a 3D design with details drawings of my dream house.',
      title: 'Interior design illustrator',
    }),
  ]);
}

export async function constructInitialStoreStateForAppStoreScreenshots(
  employer: User,
): Promise<void> {
  // Create the App Store screenshot user profile.
  await constructAppStoreProfileState(employer);
  await createProjectViewUser({ user: employer });

  // Create a freelancer user for pages below.
  const freelancer = await createUser({
    ...freelancerUser(),
    // Fixed user ID so that we can have a constant avatar for app store screenshots.
    userId: 2,
    username: 'alexanderL',
    displayName: 'Alexander L.',
  });
  await createProjectViewUser({ user: freelancer });

  // Setup initial state for user profile page.
  await constructFreelancerProfilePageState({
    employer,
    freelancer,
  });

  // Setup common user state including the project search page
  // which is needed for screenshots.
  await constructCommonUserState({ authUid: employer.id });

  // Setup user interaction to prevent showing 'New feature' modal on /search/projects
  // for appStoreScreenshotsUser.
  await createUserInteraction({
    eventName: 'search-saved-filters-onboarding',
  });

  await createUserInteraction({
    eventName: 'advanced-search-onboarding',
  });

  // Ref T254483: Clean up after A/B test
  await createUserRequiresPhoneVerification({
    userId: employer.id.toString(),
    userRequiresPhoneVerification: false,
  });

  // Setup initial state for PVP payments tab.
  await constructAppStoreProjectViewPagePaymentTabState(
    employer.id,
    freelancer,
  );
}

/**
 * Create a project with a quotation and subscription items
 */
async function createQuotationProject({
  freelancer,
  employer,
}: {
  readonly freelancer: User;
  readonly employer: User;
}): Promise<ProjectViewProject> {
  const projectId = generateId();

  // The project was created 35 days ago, and we have the first month paid
  // for and the next month is in progress (with a milestone made 5 days ago)
  const projectStartDate = Date.now() - 1000 * 60 * 60 * 24 * 35;
  const secondPaymentDate = projectStartDate + 1000 * 60 * 60 * 24 * 30;

  const awardedBids = await createBids({
    ...acceptedBid({ bidderIds: [freelancer.id] }),
    projectId,
    projectOwnerId: employer.id,
  });
  await createCurrencies();

  const project = await createProjectViewProject({
    title: 'Build and maintain my website',
    projectId,
    ownerId: employer.id,
    ...fixedUsdProject(),
    ...awardedProject({ selectedBids: awardedBids }),
    timeSubmitted: projectStartDate,
  });

  const quotation = await createQuotationRevision({
    title: 'Build and maintain my website',
    description: 'Make is awesome',
    status: QuotationStatusApi.APPROVED,
    createDate: projectStartDate,
    ...currencyUSD(),
    creatorId: freelancer.id,
    contextId: awardedBids[0].id,
    contextType: QuotationContextTypeApi.BID,
  });

  // A fixed quotation item with a milestone
  const quotationItemRevisionFixed = await createQuotationItemRevision({
    ...attachedToQuotation({ quotation }),
    name: 'Build my website',
    unitPrice: 1000,
    quotationItemType: QuotationItemType.SUBSCRIPTION,
  });

  await createMilestone({
    timeCreated: projectStartDate,
    ...milestoneFromBid(awardedBids[0]),
    ...milestoneFromQuotationItemRevision({
      quotationItemRevision: quotationItemRevisionFixed,
    }),
    ...usdCurrency(),
    ...frozenMilestone(),
  });

  // A subscription quotation item with multiple milestones and payments
  const firstQuotationItemRevisionForSubscription =
    await createQuotationItemRevision({
      ...attachedToQuotation({ quotation }),
      name: 'Keep my website running',
      unitPrice: 100,
      billingFrequency: QuotationItemBillingFrequencyApi.MONTHLY,
      quotationItemType: QuotationItemType.SUBSCRIPTION,
    });

  const firstSubscriptionItem = await createSubscriptionItem({
    quotationItemVersionId: firstQuotationItemRevisionForSubscription.id,
    createDate: projectStartDate,
    projectId,
    status: SubscriptionItemStatusApi.ACTIVE,
  });

  const firstSubscriptionPreviousMilestone = await createMilestone({
    timeCreated: projectStartDate,
    ...milestoneFromBid(awardedBids[0]),
    ...milestoneFromQuotationItemRevision({
      quotationItemRevision: firstQuotationItemRevisionForSubscription,
    }),
    ...usdCurrency(),
    ...clearedMilestone(),
  });

  await createScheduledSubscriptionPayment({
    subscriptionItemId: firstSubscriptionItem.id,
    agreedBillingDate: projectStartDate,
    status: ScheduledSubscriptionPaymentStatusApi.SUCCESS,
    milestoneId: firstSubscriptionPreviousMilestone.id,
  });

  const firstSubscriptionCurrentMilestone = await createMilestone({
    timeCreated: secondPaymentDate,
    ...milestoneFromBid(awardedBids[0]),
    ...milestoneFromQuotationItemRevision({
      quotationItemRevision: firstQuotationItemRevisionForSubscription,
    }),
    ...usdCurrency(),
    ...frozenMilestone(),
  });

  await createScheduledSubscriptionPayment({
    subscriptionItemId: firstSubscriptionItem.id,
    agreedBillingDate: secondPaymentDate,
    status: ScheduledSubscriptionPaymentStatusApi.PENDING,
    milestoneId: firstSubscriptionCurrentMilestone.id,
  });

  // Another subscription quotation item with multiple milestones and payments
  const secondQuotationItemRevisionForSubscription =
    await createQuotationItemRevision({
      ...attachedToQuotation({ quotation }),
      name: 'Keep the SEO great',
      unitPrice: 20,
      billingFrequency: QuotationItemBillingFrequencyApi.MONTHLY,
      quotationItemType: QuotationItemType.SUBSCRIPTION,
    });
  const secondSubscriptionItem = await createSubscriptionItem({
    quotationItemVersionId: secondQuotationItemRevisionForSubscription.id,
    createDate: projectStartDate,
    projectId,
    status: SubscriptionItemStatusApi.ACTIVE,
  });

  const secondSubscriptionPreviousMilestone = await createMilestone({
    timeCreated: projectStartDate,
    ...milestoneFromBid(awardedBids[0]),
    ...milestoneFromQuotationItemRevision({
      quotationItemRevision: secondQuotationItemRevisionForSubscription,
    }),
    ...usdCurrency(),
    ...clearedMilestone(),
  });

  await createScheduledSubscriptionPayment({
    subscriptionItemId: secondSubscriptionItem.id,
    agreedBillingDate: projectStartDate,
    status: ScheduledSubscriptionPaymentStatusApi.SUCCESS,
    milestoneId: secondSubscriptionPreviousMilestone.id,
  });

  const secondSubscriptionCurrentMilestone = await createMilestone({
    timeCreated: secondPaymentDate,
    ...milestoneFromBid(awardedBids[0]),
    ...milestoneFromQuotationItemRevision({
      quotationItemRevision: secondQuotationItemRevisionForSubscription,
    }),
    ...usdCurrency(),
    ...frozenMilestone(),
  });

  await createScheduledSubscriptionPayment({
    subscriptionItemId: secondSubscriptionItem.id,
    agreedBillingDate: secondPaymentDate,
    status: ScheduledSubscriptionPaymentStatusApi.PENDING,
    milestoneId: secondSubscriptionCurrentMilestone.id,
  });

  return project;
}
