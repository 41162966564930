import type { EnvironmentConfig } from './environment.types';

export const environment: EnvironmentConfig = {
  appName: 'bits',
  environmentName: 'prod',
  production: true,
  useFakes: false,
  blankState: false,
  canvasHost: undefined,
  authConfig: {
    baseUrl: 'https://www.freelancer.com/auth',
    switchAccountHackyEndpoint:
      'https://www.freelancer.com/ajax-api/navigation/switchaccount.php',
    authHashCookie: 'GETAFREE_AUTH_HASH_V2',
    userIdCookie: 'GETAFREE_USER_ID',
    csrfCookie: 'XSRF-TOKEN',
    authHeaderName: 'freelancer-auth-v2',
    authIosKeychainToken: 'freelancer-auth-ios-keychain',
    gdprCookie: undefined,
    referralCookie: undefined,
  },
  adminAuthConfig: {
    baseUrl: undefined,
    authHashCookie: undefined,
    userIdCookie: undefined,
    authHeaderName: undefined,
  },
  captchaConfig: {
    recaptchaPublicKey: '6Lc1CCcTAAAAABxlulYmWJj_ZNAHHegrhLV3vS2Z',
  },
  uiConfig: {
    assetsBaseUrl: 'https://www.f-cdn.com/assets/bits/en/assets',
    theme: undefined,
  },
  mapsConfig: {
    apiKey: 'AIzaSyCZOeorPp8BMs6k3E6cMqRuCdSqR4CSIDc',
  },
  datastoreConfig: {
    webSocketUrl: 'https://notifications.freelancer.com',
    enableStoreFreeze: false,
  },
  threatmetrixConfig: {
    profilingDomain: undefined,
    orgId: undefined,
  },
  freelancerHttpConfig: {
    baseUrl: 'https://www.freelancer.com',
    apiBaseUrl: 'https://www.freelancer.com/api',
    ajaxBaseUrl: 'https://www.freelancer.com/ajax-api',
    authBaseUrl: 'https://www.freelancer.com/auth',
    fileServiceBaseUrl: 'https://www.freelancer.com/fileservice/v4/upload',
  },
  trackingConfig: {
    trackingEndpoint: 'https://t.freelancer.com/events',
    linkSessionsEndpoint: 'https://t.freelancer.com/link',
    sessionCookie: '_tracking_session',
    linkedSessionCookie: 'uniform_id_linked',
    clientKey: '_tracking_client_session',
    navigationPerformanceSamplingRate: 1,
    enableErrorTracking: true,
    sentryDsn: 'https://29cfb544702b48e0985e69cd3ef5e84f@sentry.io/1410063',
    tracesSampleRate: 0,
    gaTrackingId: undefined,
    ga4TrackingId: undefined,
    awGoogleConversionId: undefined,
    facebookPixelId: undefined,
    sentryEnvironment: undefined,
    kibanaBaseUrl: undefined,
    gaCustomDimensionMap: {
      dimension1: undefined,
      dimension2: undefined,
      dimension3: undefined,
    },
    gAdsTrackingId: undefined,
    gAdsAllowedDomain: undefined,
    linkedInPixelId: undefined,
    quoraPixelId: undefined,
    hotjarId: 1_223_449,
    tiktokPixelIdMap: {
      australiaNewZealand: undefined,
      canadaAmerica: undefined,
    },
  },
  billingConfig: {
    baseUrl: 'https://api.flnbln.net',
  },
  facebookConfig: {
    appId: undefined,
  },
  languageCookies: {
    webappCookie: 'GETAFREE_LANGUAGE',
    nativeCookie: 'GETAFREE_MOBILELANGUAGE',
  },
  seoConfig: {
    metaTags: {
      robots: {
        name: 'robots',
        content: 'index, follow',
      },
      alexa: { name: undefined, content: undefined },
      bing: { name: undefined, content: undefined },
    },
    linkTags: {
      rss: {
        rel: undefined,
        type: undefined,
        title: undefined,
        href: undefined,
      },
    },
    defaultMetaImage: 'app-icons/share-make-it-real-thumbnail-1200x630.jpg',
  },
  whitelistCookie: 'IS_WHITELIST_USER',
  siteName: 'Freelancer Bits',
  loginConfig: {
    loggedOutRestricted: false,
    defaultLogoutRedirectUrl: '/',
    ssoIntegration: undefined,
  },
  pwaConfig: {
    appIcon: undefined,
  },
  firebase: {
    apiKey: undefined,
    projectId: undefined,
    messagingSenderId: undefined,
    appId: undefined,
  },
  googleSignInConfig: {
    clientId: undefined,
  },
  messaging: {
    aiChatUserId: 3_779_912,
  },
  lightGallery: {
    licenseKey: 'A6ACF584-D992-4C7C-B6EA-2CB459B194E8',
  },
  applePayConfig: {
    merchantIdentifier: undefined,
  },
  contractsConfig: {
    baseUrl: 'https://contracts.freelancer.com',
  },
};
