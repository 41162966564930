import type { QHPCreateType, TimeMilliseconds } from '@freelancer/datastore';
import type { RecursivePartial } from '@freelancer/datastore/core';
import { NotificationProjectType } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  ProjectTitleEditRequestStatusApi,
  ProjectTypeApi,
} from 'api-typings/projects/projects';
import type { Bid } from '../bids/bids.model';
import { AWARD_EXPIRY_INTERVAL } from '../bids/bids.model';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { Currency } from '../currencies/currencies.model';
import { Enterprise } from '../enterprise/enterprise.model';
import { ClientViewedBidNotificationType } from '../insights-bids';
import type { Milestone } from '../milestones';
import type { ProjectViewProject } from '../project-view-projects';
import { generateProjectViewProjectObject } from '../project-view-projects/project-view-projects.seed';
import type { Project } from '../projects/projects.model';
import type { User } from '../users/users.model';
import { generateAvatar, generateUserObject } from '../users/users.seed';
import type {
  NewsfeedInviterExternalReferenceExpiry,
  NotificationAccepted,
  NotificationActivateFreelancer,
  NotificationAward,
  NotificationAwardProjectCorporateTeam,
  NotificationBid,
  NotificationBookmarkedProjectAwarded,
  NotificationClientViewedBid,
  NotificationContestPCB,
  NotificationCorporateTeamBidPlaced,
  NotificationCreateMilestone,
  NotificationCustomAdminNotification,
  NotificationDenied,
  NotificationDraftContest,
  NotificationEscalateDispute,
  NotificationHireMe,
  NotificationIndianMandateMembershipRecurringReminder,
  NotificationIndianMandateMembershipVerificationReminder,
  NotificationInternalLinkedProjectAwardReminder,
  NotificationInviteUserBid,
  NotificationProjectCompleted,
  NotificationProjectHireMeExpired,
  NotificationProjectTitleEditRequest,
  NotificationRejected,
  NotificationReleaseMilestone,
  NotificationRequestMilestone,
  NotificationRequestToRelease,
  NotificationReviewActivate,
  NotificationReviewPostedNew,
  NotificationSendQuote,
  NotificationShowcaseClientNotification,
  NotificationShowcaseSourceApproval,
  NotificationSuggestionForFreelancerAfterReceiveReview,
  NotificationUpgradeToNonFreeMembership,
  NotificationUploadFile,
  NotificationWelcome,
} from './newsfeed.model';

export interface GenerateNotificationProjectAcceptedOptions {
  readonly freelancer?: User;
  readonly projectId?: number;
  readonly projectTitle?: string;
  readonly projectType?: NotificationProjectType;
  readonly projectSeoUrl?: string;
  readonly isAutomaticPayments?: boolean;
  readonly isHourly?: boolean;
  readonly isInsource?: boolean;
  readonly milestoneCreated?: boolean;
}

export function generateNotificationAcceptedObject({
  freelancer = generateUserObject(),
  projectId = generateId(),
  projectTitle = 'Test project',
  projectType = NotificationProjectType.NORMAL,
  projectSeoUrl = `project-${projectId}`,
  isAutomaticPayments = false,
  isHourly = false,
  isInsource = false,
  milestoneCreated = false,
}: GenerateNotificationProjectAcceptedOptions = {}): TimeMilliseconds &
  NotificationAccepted {
  return {
    type: 'accepted',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      id: projectId, // project ID
      imgUrl: freelancer.avatar ?? generateAvatar(0),
      isAutomaticPayments, // TODO: T267853 - what does this mean?
      isHourly,
      isInsource,
      milestoneCreated,
      name: projectTitle,
      projectType,
      publicName: freelancer.displayName,
      seoUrl: projectSeoUrl,
      userName: freelancer.username,
      userId: freelancer.id, // freelancer
    },
  };
}

export interface GenerateNotificationActivateFreelancerOptions {
  readonly freelancer?: User;
  readonly key?: string;
  readonly updatedProfile?: boolean;
  readonly verifiedEmail?: boolean;
}

export function generateNotificationActivateFreelancerObject({
  freelancer = generateUserObject(),
  key = 'test',
  updatedProfile = false,
  verifiedEmail = false,
}: GenerateNotificationActivateFreelancerOptions = {}): TimeMilliseconds &
  NotificationActivateFreelancer {
  return {
    type: 'activateFreelancer',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      id: freelancer.id, // User id
      imgUrl: undefined,
      key,
      updatedProfile,
      verifiedEmail,
    },
  };
}

export interface GenerateNotificationReviewPostedNewOptions {
  readonly invoiceId?: number;
  readonly project: ProjectViewProject;
  readonly user?: User;
}

export function generateNotificationReviewPostedNewObject({
  invoiceId,
  project,
  user = generateUserObject(),
}: GenerateNotificationReviewPostedNewOptions): TimeMilliseconds &
  NotificationReviewPostedNew {
  return {
    type: 'reviewpostednew',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      state: '',
      imgUrl: user.avatar ?? generateAvatar(0),
      invoiceId,
      projectId: project.id,
      projectName: project.title,
      isHourlyProject: true,
      publicName: user.displayName,
      seoUrl: project.seoUrl,
      username: user.username,
      userId: user.id,
    },
  };
}
export interface GenerateNotificationReviewActivateOptions {
  readonly project?: ProjectViewProject;
  readonly enterpriseLinkedInternalProject?: ProjectViewProject;
  readonly quickHireFreelancer?: User;
  readonly isAutomaticPayments?: boolean;
  readonly isHourly?: boolean;
  readonly isMilestoneCreated?: boolean;
  readonly isQHP?: boolean;
  readonly notShowEmployerFee?: boolean;
  readonly projectCreateType?: QHPCreateType | null;
}

export function generateNotificationReviewActivateObject({
  project = generateProjectViewProjectObject({ ownerId: generateId() }),
  enterpriseLinkedInternalProject = undefined,
  quickHireFreelancer = generateUserObject(),
  isAutomaticPayments = false,
  isHourly = false,
  isMilestoneCreated = false,
  isQHP = false,
  notShowEmployerFee = true,
  projectCreateType = null,
}: GenerateNotificationReviewActivateOptions = {}): TimeMilliseconds &
  NotificationReviewActivate {
  const externalProjectIds = enterpriseLinkedInternalProject
    ?.enterpriseLinkedProjectsDetails?.isInternal
    ? enterpriseLinkedInternalProject?.enterpriseLinkedProjectsDetails?.externalProjects?.map(
        externalProject => externalProject.id,
      )
    : undefined;
  return {
    type: 'reviewActivate',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      currencyId: project.currency.id,
      enterpriseId: project.enterpriseIds
        ? project.enterpriseIds[0]
        : undefined,
      enterpriseLinkedInternalProjectId: !project
        .enterpriseLinkedProjectsDetails?.isInternal
        ? project.enterpriseLinkedProjectsDetails?.internalProjectId
        : undefined,
      enterpriseLinkedExternalProjectIds: externalProjectIds,
      enterpriseLinkedInternalProjectTitle: enterpriseLinkedInternalProject
        ? enterpriseLinkedInternalProject.title
        : null,
      enterpriseLinkedInternalProjectSeoUrl: enterpriseLinkedInternalProject
        ? enterpriseLinkedInternalProject.seoUrl
        : null,
      id: project.id,
      imgUrl: undefined,
      isAutomaticPayments,
      isHourly,
      isMilestoneCreated,
      isQHP,
      name: project.title,
      notShowEmployerFee,
      projectCreateType,
      quickhireFreelancerId: isQHP ? quickHireFreelancer?.id : null,
      quickhireFreelancerPublicName: isQHP
        ? quickHireFreelancer?.displayName
        : null,
      quickhireFreelancerUsername: isQHP ? quickHireFreelancer?.username : null,
      seoUrl: `project-${project.id}`,
    },
  };
}

export interface GenerateNotificationRejectedOptions {
  readonly project?: ProjectViewProject;
  readonly enterpriseLinkedInternalProject?: ProjectViewProject;
  readonly rejectReasons?: readonly string[];
  readonly additionalMsg?: string;
}

export function generateNotificationRejectedObject({
  project = generateProjectViewProjectObject({ ownerId: generateId() }),
  enterpriseLinkedInternalProject = undefined,
  rejectReasons = ['Please correct some details before posting your project.'],
  additionalMsg = 'Kindly provide a better project description to help potential bidders understand your project.' +
    'You may also need to review your project title, add more relevant skills or adjust your budget range.',
}: GenerateNotificationRejectedOptions = {}): TimeMilliseconds &
  NotificationRejected {
  const externalProjectIds = enterpriseLinkedInternalProject
    ?.enterpriseLinkedProjectsDetails?.isInternal
    ? enterpriseLinkedInternalProject?.enterpriseLinkedProjectsDetails?.externalProjects?.map(
        externalProject => externalProject.id,
      )
    : undefined;
  return {
    type: 'rejected',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      enterpriseId: project.enterpriseIds
        ? project.enterpriseIds[0]
        : undefined,
      enterpriseLinkedInternalProjectId: !project
        .enterpriseLinkedProjectsDetails?.isInternal
        ? project.enterpriseLinkedProjectsDetails?.internalProjectId
        : undefined,
      enterpriseLinkedExternalProjectIds: externalProjectIds,
      latestExternalLinkedProjectId:
        externalProjectIds && externalProjectIds.length
          ? Math.max(...externalProjectIds)
          : null,
      enterpriseLinkedInternalProjectTitle: enterpriseLinkedInternalProject
        ? enterpriseLinkedInternalProject.title
        : null,
      enterpriseLinkedInternalProjectSeoUrl: enterpriseLinkedInternalProject
        ? enterpriseLinkedInternalProject.seoUrl
        : null,
      imgUrl: undefined,
      rejectReasons,
      additionalMsg,
      projectName: project.title,
      projectId: project.id,
    },
  };
}

export interface GenerateNotificationAwardOptions {
  readonly employer?: User;
  readonly project?: ProjectViewProject;
  readonly currency?: Currency;
  readonly bidAmount?: number;
  readonly bidPeriod?: number;
  // FIXME: T267853 - not project.status but some custom string. Should be an enum, one of
  // the values seems to be 'rejected'
  readonly state?: string;
}

export function generateNotificationAwardObject({
  employer = generateUserObject(),
  project = generateProjectViewProjectObject({ ownerId: generateId() }),
  currency = generateCurrencyObject(CurrencyCode.USD),
  bidAmount = 20,
  bidPeriod = 7,
  state,
}: GenerateNotificationAwardOptions = {}): TimeMilliseconds &
  NotificationAward {
  return {
    type: 'award',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      bid: {
        amount: bidAmount,
        period: bidPeriod,
      },
      currency: {
        code: currency.code,
        sign: currency.sign,
      },
      acceptByTime: Date.now() + AWARD_EXPIRY_INTERVAL,
      appendedDescr: project.description ?? 'Test description',
      jobString: project.skills.map(skill => skill.name).join(', '),
      id: project.id,
      imgUrl: employer.avatar ?? generateAvatar(0),
      linkUrl: `/projects/${project.seoUrl}`,
      projIsHourly: !!project.hourlyProjectInfo,
      publicName: employer.displayName,
      state,
      title: project.title,
      username: employer.username,
      userId: employer.id,
    },
  };
}

export interface GenerateNotificationBidOptions {
  readonly bid: Bid;
  readonly bids: readonly Bid[];
  readonly bidder: User;
  readonly project: ProjectViewProject;
}

// NewsfeedItemBidComponent creates its own `NotificationBid` object from various
// datastore calls, so this may not be used
export function generateNotificationBidObject({
  bid,
  bids,
  bidder,
  project,
}: GenerateNotificationBidOptions): TimeMilliseconds & NotificationBid {
  if (!bids.length) {
    throw new Error('Provide at least one bid to create a NotificationBid');
  }

  return {
    type: 'bid',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      bidList: [], // transformer says this is always empty
      amount: bid.amount.toString(),
      bidAvg: bids.reduce((acc, b) => acc + b.amount, 0) / bids.length,
      bidCount: bids.length,
      bidId: bid.id,
      bidIsExternal: bid.projectId !== project.id,
      imgUrl: bidder.avatar,
      linkUrl: `/projects/${project.seoUrl}?gotoBid=${bid.id}`,
      projectId: project.id,
      projName: project.title,
      projIsHourly: !!project.hourlyProjectInfo,
      projIsInsource: project.isInsourceProject,
      projIsToken: project.isTokenProject,
      projSeoUrl: `/projects/${project.seoUrl}`,
      publicName: bidder.displayName,
      title: bid.description ?? '', // not project title, unused
      userId: bidder.id,
      username: bidder.username,
      description: bid.description,
      submitDate: bid.submitDate,
      currency: project.currency,
      period: bid.period.toString(),
      userAvatar: bidder.avatar,
    },
  };
}

export interface GenerateNotificationInviteUserBidOptions {
  readonly inviteeId: number;
  readonly inviteeName: string;
  readonly maxBudget: number;
  readonly minBudget: number;
  readonly project: ProjectViewProject;
  readonly user: User;
}

// NewsfeedItemBidComponent creates its own `NotificationBid` object from various
// datastore calls, so this may not be used
export function generateNotificationInviteUserBidObject({
  project,
  user,
  inviteeId = generateId(),
  inviteeName = 'test name',
  maxBudget = 1000,
  minBudget = 100,
}: GenerateNotificationInviteUserBidOptions): TimeMilliseconds &
  NotificationInviteUserBid {
  return {
    type: 'inviteUserBid',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      appendedDescr: 'test description',
      id: project.id,
      imgUrl: user.avatar ?? generateAvatar(0),
      isTest: false,
      skillIds: [],
      jobString: '',
      linkUrl: '',
      text: '',
      title: project.title,
      userId: user.id,
      username: user.username,
      currencyCode: project.currency.code,
      currencySign: project.currency.sign,
      inviteeId,
      inviteeName,
      maxBudget,
      minBudget,
      projIsHourly: true,
      publicName: user.displayName,
    },
  };
}

export interface GenerateNotificationProjectTitleEditRequestOptions {
  readonly id: number;
  readonly newTitle: string;
  readonly oldTitle: string;
  readonly projectId: number;
  readonly requestedBy: number;
}

export function generateNotificationProjectTitleEditRequestObject({
  id = generateId(),
  newTitle = 'test new title',
  oldTitle = 'test old title',
  projectId = generateId(),
  requestedBy = generateId(),
}: GenerateNotificationProjectTitleEditRequestOptions): TimeMilliseconds &
  NotificationProjectTitleEditRequest {
  return {
    type: 'projectTitleEditRequest',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      id,
      projectId,
      newTitle,
      requestedBy,
      status: ProjectTitleEditRequestStatusApi.ACCEPTED,
      oldTitle,
    },
  };
}

export interface GenerateNotificationBookmarkedProjectAwardedOptions {
  readonly project: ProjectViewProject;
  readonly user: User;
  readonly bidAmount: number;
}

export function generateNotificationBookmarkedProjecAwardedObject({
  project,
  user,
  bidAmount,
}: GenerateNotificationBookmarkedProjectAwardedOptions): NotificationBookmarkedProjectAwarded {
  return {
    type: 'bookmarkedProjectAwarded',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      bidAmount,
      currencyCode: project.currency.code,
      projectId: project.id,
      projectSeoUrl: project.seoUrl,
      projIsHourly: project.type === ProjectTypeApi.HOURLY,
      publicName: user.displayName,
      title: project.title,
      userId: user.id,
      username: user.username,
    },
  };
}

export interface GenerateNotificationContestPcbOptions {
  readonly employer?: User;
  readonly contestId?: number;
  readonly contestSeoUrl?: string;
  readonly contestName?: string;
}

export function generateNotificationContestPcbObject({
  employer = generateUserObject(),
  contestId = generateId(),
  contestSeoUrl = `contest-${contestId}`,
  contestName = 'Test contest',
}: GenerateNotificationContestPcbOptions = {}): TimeMilliseconds &
  NotificationContestPCB {
  return {
    type: 'contest_pcb',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      contestId,
      contestSeoUrl,
      contestName,
      imgUrl: employer.avatar ?? generateAvatar(0),
      messagesOverWs: 1,
      publicName: employer.displayName,
      username: employer.username,
    },
  };
}

export interface GenerateNotificationDraftContestOptions {
  readonly contestId?: string;
  readonly contestName?: string;
  readonly employer?: User;
  readonly isPublished?: boolean;
  readonly linkUrl?: string;
}

export function generateNotificationDraftContestObject({
  contestId = generateId().toString(),
  contestName = 'Test contest',
  employer = generateUserObject(),
  isPublished = false,
  linkUrl = 'test link url',
}: GenerateNotificationDraftContestOptions = {}): TimeMilliseconds &
  NotificationDraftContest {
  return {
    type: 'draftContest',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      contestEditUrl: undefined,
      contestId,
      contestName,
      imgUrl: employer.avatar ?? generateAvatar(0),
      isPublished,
      linkUrl,
    },
  };
}

export interface GenerateNotificationCustomAdminNotificationOptions {
  readonly notificationDescription?: string;
  readonly notificationText?: string;
  readonly linkText?: string;
  readonly linkUrl?: string;
}

export function generateNotificationCustomAdminNotificationObject({
  notificationDescription = 'Test description',
  notificationText = 'Test notification',
  linkText = 'test link',
  linkUrl = 'test link',
}: GenerateNotificationCustomAdminNotificationOptions = {}): TimeMilliseconds &
  NotificationCustomAdminNotification {
  return {
    type: 'customAdminNotification',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      description: notificationDescription,
      id: '',
      imgUrl: undefined,
      linkText,
      linkUrl,
      text: notificationText,
    },
  };
}

export interface GenerateNotificationHireMeOptions {
  readonly acceptByTime?: number;
  readonly appendedDescr?: string;
  readonly employer?: User;
  readonly jobString?: string;
  readonly linkUrl?: string;
  readonly project: ProjectViewProject;
  readonly projectPricePeriod?: number;
  readonly projIsHourly?: boolean;
  readonly sum?: number;
  readonly title?: string;
}

export function generateNotificationHireMeObject({
  acceptByTime = Date.now(),
  appendedDescr = 'Test description',
  employer = generateUserObject(),
  jobString = 'test jobString',
  linkUrl = 'test link',
  project,
  projectPricePeriod = 1,
  projIsHourly = true,
  sum = 1000,
  title = 'test',
}: GenerateNotificationHireMeOptions): TimeMilliseconds & NotificationHireMe {
  return {
    type: 'hireMe',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      acceptByTime,
      appendedDescr,
      currency: {
        code: 'AUD',
        sign: '$',
      },
      id: project.id,
      imgUrl: employer.avatar ?? generateAvatar(0),
      jobString,
      linkUrl,
      period: projectPricePeriod,
      projIsHourly,
      publicName: employer.displayName,
      state: undefined,
      sum,
      title,
      userId: employer.id,
      username: employer.username,
    },
  };
}

export interface GenerateNotificationHireMeExpiredOptions {
  readonly project?: ProjectViewProject;
  readonly seoUrl?: string;
  readonly user?: User;
}

export function generateNotificationHireMeExpiredObject({
  project = generateProjectViewProjectObject({ ownerId: generateId() }),
  seoUrl = `project-${project.id}`,
  user = generateUserObject(),
}: GenerateNotificationHireMeExpiredOptions = {}): TimeMilliseconds &
  NotificationProjectHireMeExpired {
  return {
    type: 'projectHireMeExpired',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      autoRepostProjectId: undefined,
      autoRepostProjectName: undefined,
      imgUrl: undefined,
      jobsUrl: '',
      linkUrl: '',
      name: project.title,
      projectId: project.id,
      projectName: project.title,
      publicName: user.displayName,
      seoUrl,
      userId: user.id,
      username: user.username,
    },
  };
}

export interface GenerateNotificationDeniedOptions {
  readonly autoRepostProjectId?: number;
  readonly projectId?: number;
  readonly projectName?: string;
  readonly projectType?: NotificationProjectType;
  readonly seoUrl?: string;
  readonly user?: User;
}

export function generateNotificationDeniedObject({
  autoRepostProjectId = generateId(),
  projectId = generateId(),
  projectName = 'test project',
  projectType = NotificationProjectType.NORMAL,
  seoUrl = `project-${projectId}`,
  user = generateUserObject(),
}: GenerateNotificationDeniedOptions = {}): TimeMilliseconds &
  NotificationDenied {
  return {
    type: 'denyed',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      autoRepostProjectId,
      id: projectId,
      imgUrl: user.avatar ?? generateAvatar(0),
      jobsUrl: '',
      linkUrl: '',
      name: projectName,
      projectType,
      publicName: user.displayName,
      seoUrl,
      userId: user.id,
      username: user.username,
      isTokenProject: true,
      poolIds: [],
      enterpriseIds: [],
    },
  };
}
export interface GenerateNotificationInternalLinkedProjectAwardReminderOptions {
  readonly internalProject?: ProjectViewProject;
  readonly externalProjects?: readonly ProjectViewProject[];
}

export function generateNotificationInternalLinkedProjectAwardReminderObject({
  internalProject = generateProjectViewProjectObject({
    ownerId: generateId(),
    enterpriseIds: [Enterprise.DELOITTE_DC],
  }),
  externalProjects = [
    generateProjectViewProjectObject({
      ownerId: generateId(),
      enterpriseIds: [Enterprise.DELOITTE_DC],
    }),
  ],
}: GenerateNotificationInternalLinkedProjectAwardReminderOptions = {}): TimeMilliseconds &
  NotificationInternalLinkedProjectAwardReminder {
  const externalProjectStates: { [index: number]: string } = {};
  externalProjects.forEach(project => {
    externalProjectStates[project.id] = project.status[0].toUpperCase();
  });
  const externalProjectIds = externalProjects.map(project => project.id);

  return {
    type: 'internalLinkedProjectAwardReminder',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      internalProjectId: internalProject.id,
      internalProjectName: internalProject.title,
      internalProjectUrl: internalProject.seoUrl,
      externalProjectIds,
      latestExternalProjectId:
        externalProjectIds && externalProjectIds.length
          ? Math.max(...externalProjectIds)
          : null,
      externalProjectsStates: externalProjectStates,
      enterpriseId: internalProject.enterpriseIds
        ? internalProject.enterpriseIds[0]
        : Enterprise.DELOITTE_DC,
    },
  };
}

export interface GenerateNotificationMilestoneOptions {
  readonly project: ProjectViewProject;
  readonly milestone: Milestone;
  readonly user: User;
  readonly isReminder?: boolean;
  // Time when a milestone release request is made
  readonly releaseRequestTime?: number;
  // Time when a milestone request is made
  readonly requestTime?: number;
}

export function generateNotificationMilestoneCreatedObject({
  project,
  milestone,
  user,
}: GenerateNotificationMilestoneOptions): NotificationCreateMilestone {
  return {
    type: 'createMilestone',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      appendedDescr: '',
      id: project.id,
      imgUrl: '',
      isTest: false,
      skillIds: [],
      jobString: '',
      linkUrl: '',
      text: '',
      userId: user.id,
      username: '',
      accountId: user.id,
      amount: milestone.amount ? milestone.amount : 0,
      currencyCode: project.currency.code,
      currencySign: project.currency.sign,
      projectId: project.id,
      publicName: user.displayName,
      shortDescr: 'Project short description',
      title: project.title,
      isInitialPayment: false,
      transId: milestone.id,
    },
  };
}

export function generateNotificationMilestoneReleaseObject({
  project,
  milestone,
  user,
}: GenerateNotificationMilestoneOptions): NotificationReleaseMilestone {
  return {
    type: 'releaseMilestone',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      accountId: user.id,
      amount: milestone.amount ? milestone.amount : 0,
      currencyCode: project.currency.code,
      currencySign: project.currency.sign,
      imgUrl: '',
      linkUrl: '',
      name: user.displayName,
      otherReason: '',
      publicName: user.displayName,
      seoUrl: project.seoUrl,
      submitDate: '',
      title: project.title,
      userId: user.id,
      username: user.username,
    },
  };
}

export interface GenerateNotificationSendQuoteOptions {
  readonly project: Project;
  readonly user: User;
}

export function generateNotificationSendQuoteObject({
  project,
  user,
}: GenerateNotificationSendQuoteOptions): NotificationSendQuote {
  return {
    type: 'sendQuote',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      appendedDescr: '',
      id: project.id,
      imgUrl: '',
      isTest: false,
      skillIds: [],
      jobString: '',
      linkUrl: `/projects/${project.seoUrl}`,
      text: '',
      title: project.title,
      userId: user.id,
      username: user.username,
      currencyId: project.currency.id,
      employerPublicName: user.displayName,
    },
  };
}

export function generateNotificationMilestoneRequestToReleaseObject({
  project,
  milestone,
  user,
  isReminder = false,
  releaseRequestTime = undefined,
}: GenerateNotificationMilestoneOptions): NotificationRequestToRelease {
  return {
    type: 'requestToRelease',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      accountId: user.id,
      amount: milestone.amount ? milestone.amount : 0,
      bidId: milestone.bidId || generateId(),
      currencyCode: project.currency.code,
      currencySign: project.currency.sign,
      descr: milestone.description || 'A seeded requestToRelease',
      imgUrl: '',
      linkUrl: '',
      name: project.title,
      publicName: user.displayName,
      seoUrl: project.seoUrl,
      tranId: milestone.id,
      userId: user.id,
      username: user.username,
      isReminder,
      releaseRequestTime,
    },
  };
}

export function generateNotificationMilestoneRequestObject({
  project,
  milestone,
  user,
  isReminder = false,
  requestTime = undefined,
}: GenerateNotificationMilestoneOptions): NotificationRequestMilestone {
  return {
    type: 'requestMilestone',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      accountId: user.id,
      amount: milestone.amount ? milestone.amount : 0,
      bidId: milestone.bidId || 0,
      currencyCode: project.currency.code,
      currencyId:
        milestone.currency?.id || generateCurrencyObject(CurrencyCode.USD).id,
      currencySign: project.currency.sign,
      description: milestone.description || 'This is a requested milestone',
      id: project.id,
      imgUrl: '',
      linkUrl: '',
      name: project.title,
      publicName: user.displayName,
      requestId: generateId(),
      sellerId: generateId(),
      seoUrl: project.seoUrl,
      submitDate: '',
      userId: user.id,
      username: user.username,
      isReminder,
      requestTime,
    },
  };
}

export interface GenerateNotificationIndianMandateMembershipRecurringReminderOptions {
  readonly historyLogId: number;
}

export function generateNotificationIndianMandateMembershipRecurringReminderObject({
  historyLogId,
}: GenerateNotificationIndianMandateMembershipRecurringReminderOptions): NotificationIndianMandateMembershipRecurringReminder {
  return {
    type: 'indianMandateMembershipRecurringReminder',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      historyLogId,
    },
  };
}

export function generateNotificationIndianMandateMembershipVerificationReminderObject({
  historyLogId,
}: GenerateNotificationIndianMandateMembershipRecurringReminderOptions): NotificationIndianMandateMembershipVerificationReminder {
  return {
    type: 'indianMandateMembershipVerificationReminder',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      historyLogId,
    },
  };
}

export interface GenerateNotificationUpgradeToNonFreeMembershipOptions {
  readonly autoRenew?: boolean;
  readonly currencyCode?: string;
  readonly currentPlan?: string;
  readonly freeTrial?: boolean;
  readonly newPlan?: string;
  readonly price?: number;
  readonly refundedAmount?: number;
  readonly start?: string;
  readonly end?: string;
  readonly tax?: string;
  readonly timeUnit?: string;
  readonly type?: string;
}

export function generateNotificationUpgradeToNonFreeMembershipObject({
  autoRenew = true,
  currencyCode = 'AUD',
  currentPlan = 'standard',
  freeTrial = true,
  newPlan = 'premium',
  price = 1000,
  refundedAmount = 10,
  start = 'test date 1',
  end = 'test date 2',
  tax = '15',
  timeUnit = 'DAY',
  type = 'downgrade',
}: GenerateNotificationUpgradeToNonFreeMembershipOptions): NotificationUpgradeToNonFreeMembership {
  return {
    type: 'upgradeToNonFreeMembership',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      autoRenew,
      currencyCode,
      currentPlan,
      freeTrial,
      imgUrl: undefined,
      newPlan,
      price,
      refundedAmount,
      start,
      end,
      tax,
      timeUnit,
      type,
    },
  };
}

export interface GenerateExternalReferenceReminderOptions {
  readonly inviteesEmails: readonly string[];
  readonly reminderType: string;
}

export function generateExternalReferenceReminderObject({
  inviteesEmails,
  reminderType,
}: GenerateExternalReferenceReminderOptions): NewsfeedInviterExternalReferenceExpiry {
  return {
    type: 'inviterExternalReferenceExpiry',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      inviteesEmails,
      reminderType,
    },
  };
}

export interface GenerateNotificationEscalateDisputeOptions {
  readonly disputeId?: string;
  readonly project?: ProjectViewProject;
  readonly user?: User;
  readonly time?: number;
  readonly hasPaidArbitration?: boolean;
}

export function generateNotificationEscalateDisputeObject({
  disputeId = generateId().toString(),
  project = generateProjectViewProjectObject({ ownerId: generateId() }),
  user = generateUserObject({ userId: generateId() }),
  time = Date.now(), // used for the dispute countdown
  hasPaidArbitration = false,
}: GenerateNotificationEscalateDisputeOptions): NotificationEscalateDispute {
  return {
    type: 'escalateDispute',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      disputeId,
      imgUrl: user.avatar ?? generateAvatar(0),
      linkUrl: project.seoUrl,
      projectName: project.title,
      publicName: user.displayName,
      time,
      username: user.username,
      hasPaidArbitration,
    },
  };
}

export interface GenerateNotificationUploadFileOptions {
  readonly bidId?: number;
  readonly projectId?: number;
  readonly projectName?: string;
  readonly projectSeoUrl?: string;
  readonly user?: User;
}
export function generateNotificationUploadFileObject({
  bidId = generateId(),
  projectId = generateId(),
  projectName = 'Test project',
  projectSeoUrl = `project-${projectId}`,
  user = generateUserObject(),
}: GenerateNotificationUploadFileOptions = {}): TimeMilliseconds &
  NotificationUploadFile {
  return {
    type: 'uploadFile',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      filesList: [],
      imgUrl: user.avatar ?? generateAvatar(0),
      projectId,
      projectName,
      projectSeoUrl,
      userId: user.id,
      publicName: user.displayName,
      username: user.username,
      bidId,
      file: {
        id: generateId(),
        name: 'test',
        size: 'large',
        isDriveFile: false,
        time: Date.now(),
      },
      time: Date.now(),
    },
  };
}

export interface GenerateNotificationCorporateTeamBidPlacedOptions {
  readonly bidder?: User;
  readonly project?: ProjectViewProject;
}

export function generateNotificationCorporateTeamBidPlacedObject({
  bidder = generateUserObject({ userId: generateId() }),
  project = generateProjectViewProjectObject({ ownerId: generateId() }),
}: GenerateNotificationCorporateTeamBidPlacedOptions): NotificationCorporateTeamBidPlaced {
  return {
    type: 'corporateTeamBidPlaced',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      bidderId: bidder.id,
      bidderName: bidder.displayName,
      bidderUsername: bidder.username,
      projectId: project.id,
      projectSeoUrl: project.seoUrl,
      projectTitle: project.title,
    },
  };
}

export interface GenerateNotificationAwardProjectCorporateTeamOptions {
  readonly bid: Bid;
  readonly bidder: User;
  readonly project: ProjectViewProject;
}

export function generateNotificationAwardProjectCorporateTeamObject({
  bid,
  bidder,
  project,
}: GenerateNotificationAwardProjectCorporateTeamOptions): NotificationAwardProjectCorporateTeam {
  return {
    type: 'awardProjectCorporateTeam',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      bidAmount: bid.amount,
      bidderId: bidder.id,
      bidderName: bidder.displayName,
      bidderUsername: bidder.username,
      bidPeriod: bid.period,
      currencyCode: project.currency.code,
      projectId: project.id,
      projectIsHourly: project.type === ProjectTypeApi.HOURLY,
      projectSeoUrl: project.seoUrl,
      projectTitle: project.title,
    },
  };
}

export interface GenerateNotificationShowcaseSourceApprovalOptions {
  readonly projectId?: number;
  readonly projectTitle: string;
  readonly projectType: string;
}

export function generateNotificationShowcaseSourceApprovalObject({
  projectId,
  projectTitle,
  projectType,
}: GenerateNotificationShowcaseSourceApprovalOptions): NotificationShowcaseSourceApproval {
  return {
    type: 'showcaseSourceApproval',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      linkUrl: '/discover/publish',
      projectId: projectId ?? generateId(),
      projectTitle,
      projectType,
    },
  };
}

export interface GenerateNotificationShowcaseSourceApprovalOptions {
  readonly projectId?: number;
  readonly projectTitle: string;
  readonly projectType: string;
}

export type GenerateNotificationClientViewedBidOptions =
  RecursivePartial<NotificationClientViewedBid>;

export function generateNotificationClientViewedBidObject({
  time = Date.now(),
  id = generateId().toString(),
  data,
}: GenerateNotificationClientViewedBidOptions = {}): NotificationClientViewedBid {
  return {
    type: 'clientViewedBid',
    parent_type: 'notifications',
    id,
    time,
    data: {
      bidSeenCount: data?.bidSeenCount ?? 1,
      notificationType:
        data?.notificationType ??
        ClientViewedBidNotificationType.BETTER_BID_QUALITY,
      imgUrl: undefined,
    },
  };
}

export interface GenerateNotificationProjectCompletedOptions {
  readonly amount: number;
  readonly bidId: number;
  readonly employer: User;
  readonly freelancer: User;
  readonly selectionId: number;
  readonly project: ProjectViewProject;
}

export function generateNotificationProjectCompletedObject({
  amount = 1000,
  bidId = generateId(),
  employer,
  freelancer,
  project,
  selectionId = generateId(),
}: GenerateNotificationProjectCompletedOptions): TimeMilliseconds &
  NotificationProjectCompleted {
  return {
    type: 'completed',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      projectId: project.id,
      amount,
      categoryName: '',
      completeStatus: 'completed',
      currencyCode: project.currency.code,
      currencyId: project.currency.id,
      currencySign: project.currency.sign,
      employerId: employer.id,
      employerPublicName: employer.displayName,
      employerUsername: employer.username,
      expiredTimestamp: Date.now(),
      freelancerId: freelancer.id,
      freelancerPublicName: freelancer.displayName,
      freelancerUsername: freelancer.username,
      imgUrl: undefined,
      linkUrl: '',
      name: project.title,
      selectionId,
      seoUrl: `test-project-${generateId().toString()}`,
      state: undefined,
      submitDate: Date.now().toString(),
      bidId,
    },
  };
}

export interface GenerateNotificationWelcomeOptions {
  readonly accountSetup?: boolean;
  readonly siteName?: string;
}

export function generateWelcomeObject({
  accountSetup = false,
  siteName = 'Loadshift',
}: GenerateNotificationWelcomeOptions = {}): TimeMilliseconds &
  NotificationWelcome {
  return {
    type: 'welcome',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      accountSetup,
      imgUrl: undefined,
      siteName,
    },
  };
}

export interface GenerateShowcaseClientNoticationOptions {
  readonly showcaseUrl: string;
}

export function generateShowcaseClientNotification({
  showcaseUrl,
}: GenerateShowcaseClientNoticationOptions): NotificationShowcaseClientNotification {
  return {
    type: 'showcaseClientNotification',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      linkUrl: `${showcaseUrl}`,
      userName: `TestUser${generateId().toString()}`,
    },
  };
}

export interface GenerateSuggestionForFreelancerAfterReceiveReviewOptions {
  readonly seoUrl?: string;
  readonly projectName?: string;
}

export function generateSuggestionForFreelancerAfterReceiveReviewObject({
  seoUrl = `test-project-${generateId().toString()}`,
  projectName = 'Test Project',
}: GenerateSuggestionForFreelancerAfterReceiveReviewOptions = {}): NotificationSuggestionForFreelancerAfterReceiveReview {
  return {
    type: 'suggestionForFreelancerAfterReceiveReview',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      imgUrl: undefined,
      seoUrl,
      projectName,
      username: 'TestUser',
    },
  };
}

// --- Mixins ---
