import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { AppleSignInResponse } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { ActivatedRoute } from '@freelancer/activated-route';
import type { SSOResponseData, SSOUser } from '@freelancer/auth';
import { AppleSignInError, Auth } from '@freelancer/auth';
import { TotpMethod } from '@freelancer/datastore/collections';
import type { FacebookAuthResponse } from '@freelancer/facebook';
import { FacebookSignInError } from '@freelancer/facebook';
import type { ResponseData } from '@freelancer/freelancer-http';
import type { GoogleAuthResponse } from '@freelancer/google-sign-in';
import { GoogleSignInError } from '@freelancer/google-sign-in';
import { Location } from '@freelancer/location';
import type { Interface } from '@freelancer/types';
import { TotpMethodsApi } from 'api-typings/auth/auth';
import type { RoleApi } from 'api-typings/common/common';
import { ErrorCodeApi } from 'api-typings/errors/errors';
import type { GafExceptionCodesApi } from 'api-typings/gaf/gaf';
import { UserChosenRoleApi } from 'api-typings/users/users';
import type {
  AuthSuccessResponseAjax,
  ChangeUserPasswordError,
  LoginError,
  PromptTwoFaAlternativesGetResultAjax,
  ResetUserPasswordPostResultAjax,
  ResetUserPasswordValidatePostResultAjax,
  TwoFactorBackoffTimeLeftGetResultAjax,
  UserCheckError,
} from '../login-signup.backend-model';
import type {
  AuthResponse,
  SSOActionResponse,
  TwoFactorResponse,
} from '../login-signup.model';
import type { LoginSignupService } from '../login-signup.service';

interface RedirectParams {
  redirectDisabled?: boolean;
  isModal?: boolean;
}

type SavedState =
  | {
      user: string;
      password: string;
    }
  | {
      ssoAuthPromise: Promise<
        ResponseData<any, string> & {
          provider: 'apple' | 'facebook';
        }
      >;
      ssoDetailsPromise: Promise<SSOUser | undefined>;
      /**
       * The original response from the appropriate FLN login endpoint
       * Should always be an `SSOActionResponse` but is typed as AuthResponse
       * to reduce type messiness in the usages
       */
      ssoActionPromise: Promise<ResponseData<AuthResponse, LoginError>>;
    };

export enum LoginTestingUsernames {
  basicEmployer = 1, // Don't start at 0
  basicFreelancer,
  experiencedEmployer,
  experiencedFreelancer,
  // Fixed user ID so that we can have a constant avatar for app store screenshots.
  appStoreScreenshotsUser = 5,
}

const usernames: { [key: string]: number } = {
  basicEmployer: LoginTestingUsernames.basicEmployer,
  basicFreelancer: LoginTestingUsernames.basicFreelancer,
  experiencedEmployer: LoginTestingUsernames.experiencedEmployer,
  experiencedFreelancer: LoginTestingUsernames.experiencedFreelancer,
  appStoreScreenshotsUser: LoginTestingUsernames.appStoreScreenshotsUser,
};

/**
 * Service which provides an interface to backend endpoints for the login/signup funnel
 */
@Injectable({
  providedIn: 'root',
})
export class LoginSignupTesting implements Interface<LoginSignupService> {
  static apiFailingEmail = 'takenemail@gmail.com';
  static apiFailingPassword = 'InvalidUIPW1!';
  private savedState: SavedState = {
    user: '',
    password: '',
  };
  private userId: number;

  private _ssoProvider: 'facebook' | 'apple' | 'google' | undefined;
  // sso provider authentication error
  private _ssoAuthErrorCode: FacebookSignInError | AppleSignInError | undefined;
  // our backend login error
  private _ssoLoginErrorCode: LoginError | undefined;
  private _ssoLoginStatus: 'token' | 'link' | 'signup' | undefined;
  private _ssoEmailPermissionDenied = false;

  private _flnErrorCode: ErrorCodeApi | undefined;
  private _changePasswordErrorCode: ErrorCodeApi | undefined;
  private _ssoUser: SSOUser | undefined;
  private _twofaRequired = false;
  private _totpMethod: TotpMethod = TotpMethod.EMAIL;

  constructor(
    private auth: Auth,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {}

  reset(): void {
    this.setSsoProvider(undefined);
    this.setSsoAuthError(undefined);
    this.setSsoLoginStatus(undefined);
    this.setSsoEmailPermissionDenied(false);

    this.setFreelancerError(undefined);
    this.setChangePasswordError(undefined);
  }

  setSsoProvider(provider: 'facebook' | 'apple' | 'google' | undefined): void {
    this._ssoProvider = provider;
  }

  setSsoAuthError(errorCode?: FacebookSignInError): void {
    this._ssoAuthErrorCode = errorCode;
  }

  setSsoLoginError(errorCode?: LoginError): void {
    this._ssoLoginErrorCode = errorCode;
  }

  setSsoLoginStatus(status?: 'token' | 'link' | 'signup'): void {
    this._ssoLoginStatus = status;
  }

  setSsoEmailPermissionDenied(emailPermissionDenied: boolean): void {
    this._ssoEmailPermissionDenied = emailPermissionDenied;
  }

  setFreelancerError(errorCode?: ErrorCodeApi): void {
    this._flnErrorCode = errorCode;
  }

  setChangePasswordError(errorCode?: ErrorCodeApi): void {
    this._changePasswordErrorCode = errorCode;
  }

  setSsoUser(ssoUser: SSOUser): void {
    this._ssoUser = ssoUser;
  }

  set2FAEnabled(enabled: boolean): void {
    this._twofaRequired = enabled;
  }

  setTotpMethod(method: TotpMethod): void {
    this._totpMethod = method;
  }

  login(
    user: string,
    password: string,
  ): Promise<ResponseData<AuthResponse, LoginError>> {
    if (password === 'password') {
      return Promise.resolve(
        this._twofaRequired
          ? ({
              status: 'success',
              result: {
                preLoginToken: 'token',
                method: this._totpMethod,
              },
            } as const)
          : ({
              status: 'success',
              result: {
                user: user in usernames ? usernames[user] : this.userId ?? 123,
                token: 'token',
              },
            } as const),
      );
    }

    return Promise.resolve({
      status: 'error',
      errorCode: ErrorCodeApi.INTERNAL_SERVER_ERROR,
    } as const);
  }

  verify(
    preLoginToken: string,
    password: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return Promise.resolve(
      password === '888888'
        ? ({
            status: 'success',
            result: { user: this.userId ?? 123, token: password },
          } as const)
        : ({
            status: 'error',
            errorCode: ErrorCodeApi.INTERNAL_SERVER_ERROR,
          } as const),
    );
  }

  resend2FACode(
    preLoginToken: string,
    method?: TotpMethod,
  ): Promise<ResponseData<TwoFactorResponse, LoginError>> {
    return Promise.resolve({
      status: 'success',
      result: { preLoginToken: 'token', backoffTimeLeft: 30 },
    } as const);
  }

  getBackoffTimeLeft(
    preLoginToken: string,
    method?: TotpMethod,
  ): Promise<ResponseData<TwoFactorBackoffTimeLeftGetResultAjax, LoginError>> {
    return Promise.resolve({
      status: 'success',
      result: { backoffTimeLeft: 0 },
    } as const);
  }

  facebookLogin(
    authResponse: FacebookAuthResponse,
  ): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    return this.ssoLoginMock();
  }

  facebookLinkLogin(
    email: string,
    password: string,
    authResponse: FacebookAuthResponse,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return this.ssoLoginLinkMock(password);
  }

  googleLogin(
    authResponse: GoogleAuthResponse,
  ): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    return this.ssoLoginMock();
  }

  googleLinkLogin(
    email: string,
    password: string,
    authResponse: GoogleAuthResponse,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return this.ssoLoginLinkMock(password);
  }

  checkUserDetails(
    user: {
      username?: string;
      email?: string;
    },
    password?: string,
  ): Promise<ResponseData<undefined, UserCheckError>> {
    if (this._flnErrorCode === ErrorCodeApi.EMAIL_BLOCKED) {
      return Promise.resolve({
        status: 'error',
        errorCode: ErrorCodeApi.EMAIL_BLOCKED,
      } as const);
    }

    return Promise.resolve(
      password !== LoginSignupTesting.apiFailingPassword &&
        user.email !== LoginSignupTesting.apiFailingEmail
        ? ({
            status: 'success',
            result: undefined,
          } as const)
        : ({
            status: 'error',
            errorCode: ErrorCodeApi.INTERNAL_SERVER_ERROR,
          } as const),
    );
  }

  signup({
    email,
    username,
    role,
    password,
    privacyConsent,
    personalUse,
    ssoDetails,
    facebookAuth,
    captcha,
  }: {
    email: string;
    username: string;
    role: RoleApi;
    password: string;
    privacyConsent: boolean;
    personalUse?: boolean;
    ssoDetails?:
      | FacebookAuthResponse
      | AppleSignInResponse
      | GoogleAuthResponse;
    facebookAuth?: FacebookAuthResponse;
    captcha?: {
      challenge: string;
      response: string;
    };
  }): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    const CAPTCHA_CHALLENGE = 'a1b2c3d4e5f6';

    if (captcha && captcha.challenge && captcha.response) {
      // 1234 comes from the fake code in the CaptchaComponent
      if (
        captcha.challenge === CAPTCHA_CHALLENGE &&
        captcha.response === '1234'
      ) {
        return Promise.resolve({
          status: 'success',
          result: { user: this.userId ?? 123, token: 'token' },
        });
      }
      return Promise.resolve({
        status: 'error',
        errorCode: ErrorCodeApi.SIGNUP_CAPTCHA_INVALID,
      });
    }

    if (this._flnErrorCode === ErrorCodeApi.SIGNUP_CAPTCHA_REQUIRED) {
      return Promise.resolve({
        status: 'error',
        errorCode: this._flnErrorCode,
        requestId: CAPTCHA_CHALLENGE,
      });
    }

    if (ssoDetails) {
      return Promise.resolve({
        status: 'success',
        result: { user: this.userId ?? 123, token: 'token' },
      } as const);
    }

    return Promise.resolve(
      password === 'password1'
        ? ({
            status: 'success',
            result: { user: this.userId ?? 123, token: 'token' },
          } as const)
        : ({
            status: 'error',
            errorCode: ErrorCodeApi.INTERNAL_SERVER_ERROR,
          } as const),
    );
  }

  /**
   * Handle successful authentication, be it on login or signup
   */
  handleSuccess(
    action: 'login' | 'signup',
    userId: number,
    authToken: string,
    rememberLogin: boolean,
    redirectParams?: RedirectParams,
  ): Promise<boolean | undefined> {
    // set auth session (automatically sets cookies)
    this.auth.setSession(userId.toString(), authToken);

    return this.getRedirectUrl(action, redirectParams).then(redirectUrl => {
      if (!redirectUrl || redirectParams?.redirectDisabled) {
        return;
      }

      if (redirectUrl.startsWith('http')) {
        // absolute URL: do a hard navigate
        return this.location.redirect(redirectUrl);
      }
      // parse query params and fragments in the nextUrl
      const urlTree = this.router.parseUrl(redirectUrl);
      return this.location.navigateByUrl(urlTree);
    });
  }

  setExtraCookies(): Promise<void> {
    return Promise.resolve();
  }

  getQuickLoginFenceCookie(): Promise<undefined> {
    return Promise.resolve(undefined);
  }

  /**
   * Logs a user in on other domains that they should be logged in on.
   * Fetches domains from `getCrossDomainSSODomains.php`, then sends requests
   * to those domains to set cookies on them.
   */
  setCrossDomainSSO(
    userId: number,
    authToken: string,
    rememberLogin: boolean,
  ): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Returns the next route to load after logging in or signing up
   *
   * Note that this is only called on successful logins and other redirections
   * may still occur for failed attempts.
   *
   * Eg. Suspended users may be redirected to the suspended account page.
   */
  getRedirectUrl(
    action: 'login' | 'signup',
    { isModal = false, redirectDisabled = false }: RedirectParams = {},
  ): Promise<string> {
    const { next, goto } = this.activatedRoute.snapshot.queryParams;
    console.log(`Ignoring these: next '${next}', goto '${goto}'.`);

    const defaultUrl = 'dashboard'; // FIXME: T267853 - Should this use next or goto?
    return Promise.resolve(defaultUrl);
  }

  triggerPasswordReset(
    email: string,
  ): Promise<ResponseData<undefined, 'UNKNOWN_ERROR'>> {
    return Promise.resolve({
      status: 'success',
      result: undefined,
    } as const);
  }

  resetPassword(
    newPassword: string,
    confirmPassword: string,
    token: string,
    userId: number,
  ): Promise<ResponseData<ResetUserPasswordPostResultAjax, 'UNKNOWN_ERROR'>> {
    return Promise.resolve({
      status: 'success',
      result: {
        authToken: '',
        userId: 0,
      },
    });
  }

  validatePasswordReset(
    token: string,
    userId: string,
  ): Promise<
    ResponseData<ResetUserPasswordValidatePostResultAjax, 'UNKNOWN_ERROR'>
  > {
    return Promise.resolve({
      status: 'success',
      result: {
        verified: false,
        username: '',
      },
    });
  }

  changePassword({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }): Promise<ResponseData<undefined, ChangeUserPasswordError>> {
    if (!this._changePasswordErrorCode) {
      return Promise.resolve({
        status: 'success',
        result: undefined,
      } as const);
    }

    switch (this._changePasswordErrorCode) {
      case ErrorCodeApi.PASSWORD_INCORRECT: {
        return Promise.resolve({
          status: 'error',
          errorCode: ErrorCodeApi.PASSWORD_INCORRECT,
        } as const);
      }
      case ErrorCodeApi.BAD_REQUEST: {
        return Promise.resolve({
          status: 'error',
          errorCode: ErrorCodeApi.BAD_REQUEST,
        } as const);
      }
      case ErrorCodeApi.FORBIDDEN: {
        return Promise.resolve({
          status: 'error',
          errorCode: ErrorCodeApi.FORBIDDEN,
        } as const);
      }
      default: {
        return Promise.resolve({
          status: 'error',
          errorCode: ErrorCodeApi.INTERNAL_SERVER_ERROR,
        } as const);
      }
    }
  }

  closeAccount({
    reason,
    canContact,
    feedback,
  }: {
    reason: string;
    canContact: boolean;
    feedback?: string;
  }): Promise<ResponseData<undefined, GafExceptionCodesApi.UNKNOWN_ERROR>> {
    return Promise.resolve({
      status: 'success',
      result: undefined,
    } as const);
  }

  deleteAccount({
    reason,
    canContact,
    feedback,
  }: {
    reason: string;
    canContact: boolean;
    feedback?: string;
  }): Promise<ResponseData<undefined, GafExceptionCodesApi.UNKNOWN_ERROR>> {
    return Promise.resolve({
      status: 'success',
      result: undefined,
    } as const);
  }

  /**
   * Saves state that needs to be shared across login / signup when switching routes
   */
  saveState(state: SavedState): void {
    this.savedState = state;
  }

  /**
   * Returns and clears the saved state.
   */
  getSavedState(): SavedState {
    const state = this.savedState;
    this.savedState = {
      user: '',
      password: '',
    };
    return state;
  }

  async getSsoErrorAuth(): Promise<
    | SSOResponseData<'facebook', FacebookAuthResponse, FacebookSignInError>
    | SSOResponseData<'apple', AppleSignInResponse, AppleSignInError>
    | SSOResponseData<'google', GoogleAuthResponse, GoogleSignInError>
  > {
    if (this._ssoProvider === 'facebook') {
      return {
        provider: 'facebook',
        status: 'error',
        errorCode: FacebookSignInError.UNKNOWN,
      };
    }
    if (this._ssoProvider === 'apple') {
      return {
        provider: 'apple',
        status: 'error',
        errorCode: AppleSignInError.UNKNOWN,
      };
    }
    if (this._ssoProvider === 'google') {
      return {
        provider: 'google',
        status: 'error',
        errorCode: GoogleSignInError.UNKNOWN,
      };
    }

    throw new Error(`SSO provider ${this._ssoProvider} not supported.`);
  }

  async getSsoSuccessAuth(): Promise<
    | SSOResponseData<'facebook', FacebookAuthResponse, FacebookSignInError>
    | SSOResponseData<'apple', AppleSignInResponse, AppleSignInError>
    | SSOResponseData<'google', GoogleAuthResponse, GoogleSignInError>
  > {
    if (this._ssoProvider === 'facebook') {
      const FB_RESPONSE: fb.StatusResponse = {
        status: 'connected',
        authResponse: {
          accessToken: '',
          data_access_expiration_time: Number.POSITIVE_INFINITY,
          expiresIn: Number.POSITIVE_INFINITY,
          signedRequest: '',
          userID: '1',
        },
      };

      return {
        provider: 'facebook',
        status: 'success',
        result: FB_RESPONSE.authResponse,
      };
    }
    if (this._ssoProvider === 'apple') {
      return {
        provider: 'apple',
        status: 'success',
        result: {
          email: 'appleuser@apple.com',
          identityToken: 'token',
          authorizationCode: 'code',
          user: '12387662',
        },
      };
    }
    if (this._ssoProvider === 'google') {
      return {
        provider: 'google',
        status: 'success',
        result: {
          credential: 'auth-sso-token-from-google',
        },
      };
    }
    throw new Error(`SSO provider ${this._ssoProvider} not supported.`);
  }

  async getSsoUserDetails(): Promise<SSOUser | undefined> {
    if (this._ssoUser) {
      return this._ssoUser;
    }

    if (this._ssoProvider === 'facebook') {
      // TODO: T267853 - Error case
      return {
        email: this._ssoEmailPermissionDenied
          ? undefined
          : 'facebookUser@gmail.com',
        name: 'Facebook',
        lastName: 'User',
        profileUrl: 'assets/bits/avatars/32-1.jpg',
      };
    }
    if (this._ssoProvider === 'apple') {
      return {
        email: this._ssoEmailPermissionDenied
          ? undefined
          : 'appleUser@gmail.com',
        name: 'Apple',
        lastName: 'User',
        profileUrl: 'assets/bits/avatars/32-1.jpg',
      };
    }
    if (this._ssoProvider === 'google') {
      return {
        email: 'googleUser@gmail.com',
        name: 'Google',
        lastName: 'User',
        profileUrl: 'assets/bits/avatars/32-1.jpg',
      };
    }

    throw new Error(`SSO provider ${this._ssoProvider} not supported.`);
  }

  appleLogin(
    authResponse: AppleSignInResponse,
  ): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    return this.ssoLoginMock();
  }

  appleLoginLink(
    email: string,
    password: string,
    appleIdentityToken: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return this.ssoLoginLinkMock(password);
  }

  ssoLoginMock(): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    if (this._ssoLoginErrorCode) {
      return Promise.resolve({
        status: 'error',
        errorCode: this._ssoLoginErrorCode,
      });
    }

    if (this._ssoLoginStatus === 'signup') {
      return Promise.resolve({
        status: 'success',
        result: { action: 'signup' },
      } as const);
    }

    if (this._ssoLoginStatus === 'link') {
      return Promise.resolve({
        status: 'success',
        result: { action: 'link' },
      } as const);
    }

    return Promise.resolve({
      status: 'success',
      result: { user: this.userId ?? 123, token: 'token' },
    } as const);
  }

  ssoLoginLinkMock(
    password: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return Promise.resolve(
      password === 'password'
        ? ({
            status: 'success',
            result: { user: this.userId ?? 123, token: 'token' },
          } as const)
        : ({
            status: 'error',
            errorCode: ErrorCodeApi.INTERNAL_SERVER_ERROR,
          } as const),
    );
  }

  /**
   * Triggers an SSO login flow.
   *
   * Calls the appropriate SDK to authenticate the user,
   * then fetches user details and sends the data to our auth backend.
   */
  triggerSSOLogin(provider: 'facebook' | 'apple' | 'google'): {
    /**
     * Promise that resolves when the user has submitted or cancelled their login,
     * returning either a successful auth response or an error
     */
    authPromise: Promise<
      | SSOResponseData<'facebook', FacebookAuthResponse>
      | SSOResponseData<'apple', AppleSignInResponse>
      | SSOResponseData<'google', GoogleAuthResponse>
    >;
    /**
     * Promise that fetches a user's details through the SSO provider
     * Errors if the initial authPromise errored
     */
    detailsPromise: Promise<SSOUser | undefined>;
    /**
     * Promise that logs the user in via the FLN auth backend
     * Errors if the initial authPromise errored
     */
    loginPromise: Promise<
      ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
    >;
  } {
    const authPromise = this._ssoAuthErrorCode
      ? this.getSsoErrorAuth()
      : this.getSsoSuccessAuth();
    const loginPromise = authPromise.then(response => {
      if (response.status === 'error') {
        // forward error from SSO attempt
        return response;
      }
      if (response.provider === 'facebook') {
        return this.facebookLogin(response.result);
      }

      if (response.provider === 'apple') {
        return this.appleLogin(response.result);
      }

      if (response.provider === 'google') {
        return this.googleLogin(response.result);
      }
      throw new Error(`SSO provider not supported.`);
    });

    const detailsPromise = authPromise.then(response => {
      if (response.status === 'error') {
        return undefined;
      }
      return this.getSsoUserDetails();
    });

    return {
      authPromise,
      detailsPromise,
      loginPromise,
    };
  }

  setUserId(userId: number): void {
    this.userId = userId;
  }

  confirmPromptTwoFa(
    uuid: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    if (
      this._flnErrorCode === ErrorCodeApi.AUTH_PRELOGIN_TOKEN_EXPIRED ||
      this._flnErrorCode === ErrorCodeApi.AUTH_2FA_BLOCKED
    ) {
      return Promise.resolve({
        status: 'error',
        errorCode: this._flnErrorCode,
      });
    }

    return Promise.resolve({
      status: 'success',
      result: {
        user: this.userId,
        token: 'token',
      },
    });
  }

  getConfirmedPromptTwoFa(
    uuid: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    if (
      this._flnErrorCode === ErrorCodeApi.AUTH_PRELOGIN_TOKEN_EXPIRED ||
      this._flnErrorCode === ErrorCodeApi.AUTH_2FA_BLOCKED
    ) {
      return Promise.resolve({
        status: 'error',
        errorCode: this._flnErrorCode,
      });
    }

    return Promise.resolve({
      status: 'success',
      result: {
        user: this.userId,
        token: 'token',
        userRole: UserChosenRoleApi.FREELANCER,
      },
    });
  }

  getPromptTwoFaAlternatives(
    preLoginToken: string,
  ): Promise<ResponseData<PromptTwoFaAlternativesGetResultAjax, LoginError>> {
    return Promise.resolve({
      status: 'success',
      result: {
        alternatives: {
          [TotpMethodsApi.EMAIL]: true,
          [TotpMethodsApi.SMS]: true,
        },
      },
    });
  }
}
