import type {
  BackendUpdateResponse,
  DatastoreInterface,
} from '@freelancer/datastore/core';
import { average, generateId } from '@freelancer/datastore/testing/helpers';
import { haversineDistance } from '@freelancer/utils';
import type {
  ExternalProjectRejectReasonApi,
  ProjectSubStatusApi,
  ProjectTypeApi,
} from 'api-typings/projects/projects';
import {
  DeliveryTypeApi,
  EnterpriseLinkedProjectsApprovalStatusApi,
  ProjectStatusApi,
} from 'api-typings/projects/projects';
import type { ContractTypeApi } from 'api-typings/users/users';
import { ContractContextTypeApi } from 'api-typings/users/users';
import type { Bid } from '../bids/bids.model';
import type { CurrencyCode } from '../currencies';
import { Pool } from '../enterprise/enterprise.model';
import type { ProjectViewBid } from '../project-view-bids/project-view-bids.model';
import type { Location } from '../project-view-users';
import {
  LocationPreset,
  generateLocationObject,
  getLocationPresets,
  getProjectViewUserLocations,
} from '../project-view-users';
import type {
  Project,
  ProjectBudget,
  ProjectUpgrades,
} from '../projects/projects.model';
import type { GenerateProjectOptions } from '../projects/projects.seed';
import { generateProjectObject } from '../projects/projects.seed';
import type { Skill } from '../skills/skills.model';
import {
  cryptocurrencySkill,
  freightSkill,
  photographySkill,
  phpSkill,
  websiteDesignSkill,
} from '../skills/skills.seed';
import type {
  DeliveryContactDetails,
  DeliveryItem,
  EnterpriseLinkedExternalProjectDetails,
  EnterpriseLinkedProjectsDetails,
  NDADetails,
  ProjectAttachment,
  ProjectClientEngagement,
  ProjectContractSignatures,
  ProjectViewProject,
  SkillAnswer,
} from './project-view-projects.model';
import {
  transformDisplayedLocation,
  transformLocationToApi,
} from './project-view-projects.transformers';
import type { ProjectViewProjectsCollection } from './project-view-projects.types';

export type GenerateProjectViewProjectOptions = GenerateProjectOptions & {
  readonly ownerId?: number;
  readonly projectId?: number;
  readonly projectType?: ProjectTypeApi;
  readonly currencyCode?: CurrencyCode;
  readonly status?: ProjectStatusApi;
  readonly subStatus?: ProjectSubStatusApi;
  readonly selectedBids?: readonly Bid[];
  readonly skills?: readonly Skill[];
  readonly location?: Location;
  readonly ndaDetails?: NDADetails;
  readonly displayLocation?: string;
  readonly budget?: ProjectBudget;
  readonly local?: boolean;
  readonly billingCode?: string;
  readonly enterpriseIds?: readonly number[];
  readonly invitedFreelancers?: readonly number[];
  readonly clientEngagement?: ProjectClientEngagement;
  readonly description?: string;
  readonly enterpriseLinkedProjectsDetails?: EnterpriseLinkedProjectsDetails;
  readonly attachments?: readonly ProjectAttachment[];
  // Project Local Details
  readonly deliveryItems?: readonly DeliveryItem[];
  readonly deliveryType?: DeliveryTypeApi;
  readonly displayEndLocation?: string;
  readonly distance?: number;
  readonly endLocation?: Location;
  readonly upgrades?: Partial<ProjectUpgrades>;
  readonly workStartDate?: number;
  readonly pickupDeliveryContact?: DeliveryContactDetails;
  readonly dropoffDeliveryContact?: DeliveryContactDetails;
  readonly contractSignatures?: readonly ProjectContractSignatures[];
  readonly serviceOfferingId?: number;
};

export function generateProjectViewProjectObject(
  options: GenerateProjectViewProjectOptions,
): ProjectViewProject {
  return {
    ...generateProjectObject(options),
    ...generateProjectViewProjectDetails(options),
  };
}

function generateProjectViewProjectDetails({
  skills = [phpSkill()],
  invitedFreelancers,
  clientEngagement,
  description = 'I need someone to make me a website for my business. It should be awesome and wiz bang.',
  location = generateLocationObject(),
  displayLocation,
  enterpriseLinkedProjectsDetails,
  contractSignatures,
  attachments = [],
  // Local Details
  deliveryItems,
  deliveryType,
  displayEndLocation,
  endLocation,
  workStartDate,
  serviceOfferingId,
  pickupDeliveryContact,
  dropoffDeliveryContact,
}: GenerateProjectViewProjectOptions): Omit<ProjectViewProject, keyof Project> {
  return {
    skills,
    attachments,
    canPostReview: {},
    description,
    location,
    true_location: location,
    displayLocation,
    qualifications: [],
    ndaDetails: { signatures: [] },
    invitedFreelancers,
    clientEngagement,
    enterpriseLinkedProjectsDetails,
    contractSignatures,
    localDetails: {
      deliveryItems,
      deliveryType,
      displayEndLocation,
      distance:
        location.mapCoordinates && endLocation?.mapCoordinates
          ? haversineDistance(
              location.mapCoordinates,
              endLocation.mapCoordinates,
            ) / 1000
          : undefined,
      endLocation,
      workStartDate,
      pickupDeliveryContact,
      dropoffDeliveryContact,
    },
    serviceOfferingId,
  };
}

// ----- Mixins -----
export function projectViewProjectFromProject(
  project: Project,
): Pick<
  GenerateProjectViewProjectOptions,
  'ownerId' | 'projectId' | 'projectType' | 'currencyCode'
> {
  return {
    // We could add more here
    ownerId: project.ownerId,
    projectId: project.id,
    projectType: project.type,
  };
}

export function websiteDesignProject(): Pick<
  GenerateProjectViewProjectOptions,
  'skills'
> {
  return {
    skills: [phpSkill(), websiteDesignSkill()],
  };
}

export function cryptocurrencyProject(): Pick<
  GenerateProjectViewProjectOptions,
  'skills'
> {
  return {
    skills: [cryptocurrencySkill()],
  };
}

// TODO: T267853 - Local projects have additional fields, add them in
export function localProject({
  skills = [photographySkill()],
}: {
  readonly skills?: readonly Skill[];
} = {}): Pick<GenerateProjectViewProjectOptions, 'local' | 'skills'> {
  if (!skills.some(job => job.local)) {
    throw new Error('A local project must have at least one local skill');
  }

  return {
    local: true,
    skills,
  };
}

export function projectFromSydney(): Pick<
  GenerateProjectViewProjectOptions,
  'location' | 'displayLocation'
> {
  const location = getProjectViewUserLocations(LocationPreset.SYDNEY);

  return {
    location,
    displayLocation: transformDisplayedLocation(
      transformLocationToApi(location),
    ),
  };
}

export function projectFromMelbourne(): Pick<
  GenerateProjectViewProjectOptions,
  'location' | 'displayLocation'
> {
  const location = getProjectViewUserLocations(LocationPreset.MELBOURNE);

  return {
    location,
    displayLocation: transformDisplayedLocation(
      transformLocationToApi(location),
    ),
  };
}

export function projectFromVaticanCity(): Pick<
  GenerateProjectViewProjectOptions,
  'location' | 'displayLocation'
> {
  const location = getProjectViewUserLocations(LocationPreset.VATICAN_CITY);

  return {
    location,
    displayLocation: transformDisplayedLocation(
      transformLocationToApi(location),
    ),
  };
}

export function linkedExternalProject({
  /** Every external project is linked to exactly one internal project */
  internalProjectId,
  /**
   * Indicates whether the external project is dual-posted.
   * If dual-posted, the external project will activate after approval.
   * If not dual-posted, after pre-approval the external project will stay as pending until
   * it gets activated by the project poster manually.
   */
  isDualPosted = true,
  /** Approval status of the internal project */
  approvalStatus = EnterpriseLinkedProjectsApprovalStatusApi.PENDING,
  /**
   * Indicates whether the project poster has manually activated the external project
   * if not dual-posted
   */
  isActivated = false,
  rejectNote = undefined,
  rejectReasons = [],
}: {
  readonly internalProjectId: number;
  readonly isDualPosted?: boolean;
  readonly approvalStatus?: EnterpriseLinkedProjectsApprovalStatusApi;
  readonly isActivated?: boolean;
  readonly rejectNote?: string;
  readonly rejectReasons?: readonly ExternalProjectRejectReasonApi[];
}): Pick<
  GenerateProjectViewProjectOptions,
  'enterpriseLinkedProjectsDetails' | 'status'
> {
  let status;
  if (approvalStatus === EnterpriseLinkedProjectsApprovalStatusApi.PENDING) {
    status = ProjectStatusApi.PENDING;
  } else if (
    approvalStatus === EnterpriseLinkedProjectsApprovalStatusApi.APPROVED
  ) {
    status = isDualPosted
      ? ProjectStatusApi.ACTIVE
      : isActivated
      ? ProjectStatusApi.ACTIVE
      : ProjectStatusApi.PENDING;
  } else {
    status = ProjectStatusApi.REJECTED;
  }

  return {
    status,
    enterpriseLinkedProjectsDetails: {
      isInternal: false,
      internalProjectId,
      isDualPosted,
      rejectReasons,
      rejectNote,
    },
  };
}

export function linkedInternalProject({
  /** Id of the linked external project*/
  externalProjectId,
  /** Pool ids for the linked external project */
  poolIds = [Pool.DELOITTE_FTN],
  /** Indicates whether the external project is dual-posted */
  isDualPosted = true,
  /** Approval status of the internal project */
  approvalStatus = EnterpriseLinkedProjectsApprovalStatusApi.PENDING,
}: {
  readonly externalProjectId: number;
  readonly poolIds?: readonly number[];
  readonly isDualPosted?: boolean;
  readonly approvalStatus?: EnterpriseLinkedProjectsApprovalStatusApi;
}): Pick<GenerateProjectViewProjectOptions, 'enterpriseLinkedProjectsDetails'> {
  const latestExternalProject = {
    id: externalProjectId,
    poolIds,
    isDualPosted,
  };

  const externalProjects = [latestExternalProject];

  return {
    enterpriseLinkedProjectsDetails: {
      isInternal: true,
      externalProjects,
      latestExternalProject,
      approvalStatus,
    },
  };
}

export function bidStatsFromBids(
  bids: readonly ProjectViewBid[],
): Pick<GenerateProjectViewProjectOptions, 'bidStats'> {
  return {
    bidStats: {
      bidCount: bids.length,
      bidAvg: average(bids.map(bid => bid.amount)),
    },
  };
}

export function rejectedProjectNote(
  rejectNote: string | undefined,
  rejectReasons: readonly {
    readonly id: number;
    readonly description: string;
  }[],
): Pick<
  EnterpriseLinkedExternalProjectDetails,
  'rejectNote' | 'rejectReasons'
> {
  return {
    rejectNote,
    rejectReasons,
  };
}

export function freightLoad({
  skills = [freightSkill()],
}: {
  readonly skills?: readonly Skill[];
} = {}): Pick<GenerateProjectViewProjectOptions, 'local' | 'skills'> {
  if (!skills.some(job => job.local)) {
    throw new Error('A freight load must have at least one local skill');
  }

  return {
    local: true,
    skills,
  };
}

// ----- Update -----

interface UpdateProjectViewBidAverageProjectOptions {
  readonly projectId: number;
  readonly bids: readonly Bid[];
}

export async function updateProjectViewProjectBidStats(
  datastore: DatastoreInterface,
  { projectId, bids }: UpdateProjectViewBidAverageProjectOptions,
): Promise<BackendUpdateResponse<ProjectViewProjectsCollection>> {
  return datastore
    .document<ProjectViewProjectsCollection>('projectViewProjects', projectId)
    .update({
      bidStats: {
        bidCount: bids.length,
        bidAvg: average(bids.map(bid => bid.amount)),
      },
    });
}

// ----- Local Details -----

// Set default delivery project fields
export function deliveryProject(): Pick<
  GenerateProjectViewProjectOptions,
  'deliveryType'
> {
  return {
    deliveryType: DeliveryTypeApi.GENERAL,
  };
}

export function asapDeliveryProject(): Pick<
  GenerateProjectViewProjectOptions,
  'deliveryType'
> {
  return {
    deliveryType: DeliveryTypeApi.HOTSHOT,
  };
}

// Add delivery items
export function withDeliveryItems(
  items: readonly DeliveryItem[] = [deliveryItemSmall()],
): Pick<GenerateProjectViewProjectOptions, 'deliveryItems'> {
  return {
    deliveryItems: items,
  };
}

export function projectToMelbourne(): Pick<
  GenerateProjectViewProjectOptions,
  'endLocation' | 'displayEndLocation'
> {
  const location = getLocationPresets(LocationPreset.MELBOURNE);

  return {
    endLocation: location,
    displayEndLocation: transformDisplayedLocation(
      transformLocationToApi(location),
    ),
  };
}

export function deliveryItemSmall(): DeliveryItem {
  return {
    id: generateId(),
    quantity: 1,
    description: 'Small Coffee Container',
    length: 100,
    width: 200,
    height: 300,
    weight: 400,
  };
}

export function deliveryItemLarge(): DeliveryItem {
  return {
    id: generateId(),
    quantity: 2,
    description: 'Coffee Counter',
    length: 1000,
    width: 2000,
    height: 3000,
    weight: 500,
  };
}

export function deliveryItemOversized(): DeliveryItem {
  return {
    id: generateId(),
    quantity: 1,
    description: 'Big bag of coffee beans',
    length: 100,
    width: 9900,
    height: 300,
    weight: 400,
  };
}

export function deliveryItemWithSkillAnswer(
  skillId: number,
  answers: readonly string[],
): DeliveryItem {
  const skillAnswers = answers.map(answer => deliveryItemSkillAnswer(answer));
  return {
    id: generateId(),
    quantity: 1,
    description: 'Tractor',
    skillAnswers: {
      [skillId]: skillAnswers,
    },
  };
}

function deliveryItemSkillAnswer(content: string): SkillAnswer {
  return {
    id: generateId(),
    questionId: generateId(),
    content,
    contextId: generateId(),
  };
}

export function fromInternalProject(internalProject: ProjectViewProject): {
  readonly internalProjectId: number;
  readonly isDualPosted: boolean;
  readonly approvalStatus: EnterpriseLinkedProjectsApprovalStatusApi;
} {
  if (
    !(
      internalProject.enterpriseLinkedProjectsDetails &&
      internalProject.enterpriseLinkedProjectsDetails.isInternal
    )
  ) {
    throw new Error('Linked External Project Details are missing');
  }
  return {
    internalProjectId: internalProject.id,
    isDualPosted:
      internalProject.enterpriseLinkedProjectsDetails.latestExternalProject
        .isDualPosted,
    approvalStatus:
      internalProject.enterpriseLinkedProjectsDetails.approvalStatus,
  };
}

export function withContactSignature({
  displayName,
  signedUserId = undefined,
  contractType,
}: {
  readonly displayName: string;
  readonly signedUserId?: number;
  readonly contractType: ContractTypeApi;
}): ProjectContractSignatures {
  return {
    contractType,
    contextType: ContractContextTypeApi.PROJECT,
    contextId: -1, // this has no effect on the test
    displayName,
    userIdTimeSignedMap: signedUserId
      ? {
          [signedUserId]: Date.now() - 100, // time in the past
        }
      : {},
    customContractId: generateId(),
  };
}
