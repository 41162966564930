import type { HourlyProjectMilestoneFee } from './hourly-project-milestone-fees.model';

export interface HourlyProjectMilestoneFeeOptions {
  readonly currencyId: number;
  readonly fee: number;
  readonly milestoneAmount: number;
  readonly projectId: number;
}

export function generateHourlyProjectMilestoneFeeObject({
  currencyId,
  fee,
  milestoneAmount,
  projectId,
}: HourlyProjectMilestoneFeeOptions): HourlyProjectMilestoneFee {
  return {
    currencyId,
    fee,
    id: `${projectId}-${milestoneAmount}`,
    milestoneAmount,
    projectId,
  };
}

export function australianHourlyProjectFees({
  milestoneAmount,
}: {
  readonly milestoneAmount: number;
}): Pick<
  HourlyProjectMilestoneFeeOptions,
  'currencyId' | 'fee' | 'milestoneAmount'
> {
  return {
    currencyId: 3,
    fee: milestoneAmount * 0.033,
    milestoneAmount,
  };
}
