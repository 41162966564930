import { generateId } from '@freelancer/datastore/testing/helpers';
import { DiscountTypeApi } from 'api-typings/common/common';
import { ServiceOfferingStatusApi } from 'api-typings/service_offerings/service_offerings';
import { v4 as uuid } from 'uuid';
import type { GenerateServiceOfferingShopCategoryServiceOptions } from '../service-offering-shop-category-services';
import { ServiceOfferingShopCategoryServicePriceType } from '../service-offering-shop-category-services';
import type { ServiceOffering } from './service-offerings.model';

const SECONDS_IN_A_MONTH = 60 * 60 * 24 * 30;

interface GenerateServiceOfferingAttachmentOptions {
  readonly attachment_uuid?: string;
  readonly create_date?: number;
  readonly delete_date?: number;
  readonly downloadable_url?: string;
}

interface GenerateServiceOfferingDiscountDetailsOptions {
  readonly discountType: DiscountTypeApi;
  readonly discountValue: number;
  readonly discountedPrice: number;
  readonly validFrom: number;
  readonly validUntil: number;
}

export interface GenerateServiceOfferingOptions {
  readonly id?: number;
  readonly createDate?: number;
  readonly creatorId?: number;
  readonly description?: string;
  readonly richDescription?: string;
  readonly jobIds?: readonly number[];
  readonly title?: string;
  readonly status?: ServiceOfferingStatusApi;
  readonly seoUrl?: string;
  readonly attachments?: readonly GenerateServiceOfferingAttachmentOptions[];
  readonly duration?: number;
  readonly price?: number;
  readonly priceType?: ServiceOfferingShopCategoryServicePriceType;
  readonly discountDetails?: GenerateServiceOfferingDiscountDetailsOptions;
}

export function generateServiceOfferingObject({
  id = generateId(),
  creatorId = generateId(),
  createDate,
  title,
  description,
  jobIds,
  status = ServiceOfferingStatusApi.DRAFT,
  seoUrl,
  attachments,
  duration = SECONDS_IN_A_MONTH,
  price = 100,
  priceType = ServiceOfferingShopCategoryServicePriceType.HAS_FIXED_ITEMS,
  discountDetails,
}: GenerateServiceOfferingOptions): ServiceOffering {
  return {
    id,
    creatorId,
    createDate: createDate || Date.now(),
    jobIds,
    title: title || `Test Service Offering ${id}`,
    description: description || `Test Service Offering Description ${id}`,
    status,
    seoUrl:
      status === ServiceOfferingStatusApi.DRAFT
        ? undefined
        : seoUrl || `test-service-offering-${id}`,
    attachments: attachments?.map(attachment => ({
      id: generateId(),
      service_offering_id: id,
      attachment_uuid: attachment.attachment_uuid || uuid(),
      create_date: attachment.create_date || Date.now(),
      delete_date: attachment.delete_date,
      downloadable_url: attachment.downloadable_url,
    })),
    duration,
    price,
    priceType,
    discountDetails,
  };
}

export function activeServiceOffering(): Pick<
  GenerateServiceOfferingOptions,
  'createDate' | 'status'
> {
  return { createDate: Date.now(), status: ServiceOfferingStatusApi.ACTIVE };
}

export function hiddenServiceOffering(): Pick<
  GenerateServiceOfferingOptions,
  'createDate' | 'status'
> {
  return { createDate: Date.now(), status: ServiceOfferingStatusApi.HIDDEN };
}

export function draftServiceOffering(): Pick<
  GenerateServiceOfferingOptions,
  'createDate' | 'status'
> {
  return { createDate: Date.now(), status: ServiceOfferingStatusApi.DRAFT };
}

export function serviceOfferingDiscountedPriceFixed(
  fullPrice = 100,
  discountValue = 10,
): Pick<
  | GenerateServiceOfferingOptions
  | GenerateServiceOfferingShopCategoryServiceOptions,
  'discountDetails'
> {
  return {
    discountDetails: {
      discountType: DiscountTypeApi.FIXED,
      discountValue,
      discountedPrice: fullPrice - discountValue,
      validFrom: Date.now() - 1000 * 60 * 60 * 24 * 7, // 1 week ago
      validUntil: Date.now() + 1000 * 60 * 60 * 24 * 14, // 2 weeks after
    },
  };
}

export function serviceOfferingDiscountedPricePercentage(
  fullPrice = 100,
  discountValue = 0.2,
  subscriptionItemsTotalPrice = 0,
): Pick<
  | GenerateServiceOfferingOptions
  | GenerateServiceOfferingShopCategoryServiceOptions,
  'discountDetails'
> {
  return {
    discountDetails: {
      discountType: DiscountTypeApi.PERCENTAGE,
      discountValue,
      discountedPrice:
        subscriptionItemsTotalPrice > 0
          ? (fullPrice - subscriptionItemsTotalPrice) * (1 - discountValue) +
            subscriptionItemsTotalPrice
          : fullPrice * (1 - discountValue),
      validFrom: Date.now() - 1000 * 60 * 60 * 24 * 7, // 1 week ago
      validUntil: Date.now() + 1000 * 60 * 60 * 24 * 14, // 2 weeks after
    },
  };
}
